export class Amateuren{
    fccid: number = 0;
    callsign: string = "";
    fullName: string = "";
    first: string = "";
    middle: string = "";
    last: string = "";
    address1: string = "";
    city: string = "";
    state: string = "";
    zip: string = "";
}