import { Component, ElementRef, ViewChild } from '@angular/core';
import { UserService } from '../user.service';
import { User } from '../user.class';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoggerService } from '../logger.service';
import { encrypt } from 'node_modules/dsi-encrypt-password/index.js';
import { DatePipe } from '@angular/common';
import { UserSettingService } from '../user-setting.service';
import { jwtDecode } from 'jwt-decode';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { LoginCredentials } from '../logincredentials.class';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent {
  public loginForm!: FormGroup
  private user: User = {} as any;
  message: string = "";
  pageTitle = "W8ZJT CallLog";
  pipe = new DatePipe('en-US');
  @ViewChild('resetForm') aForm!: ElementRef;
  username: FormControl = new FormControl('', Validators.required);
  password: FormControl = new FormControl('', Validators.required);


 
  constructor(
    private svcusr: UserService,
    private loggersvc: LoggerService,
    private formbuilder: FormBuilder,
    private setUserSvc: UserSettingService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service
  ){
    this.loginForm = this.formbuilder.group({
      username: this.username,
      password: this.password
    });
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch(Error) {
      return null;
    }
  }
  setFocus(name) {
    const ele = this.aForm.nativeElement[name];
    if (ele) {
      ele.focus();
    }
  }
  ngAfterViewInit() {
    this.setFocus('username');
  }
  ngOnInit(): void {
    var ssUserId = sessionStorage.getItem('userId');
    if(sessionStorage.getItem('isLoggedIn') == 'true' && ssUserId !== null){
      this.svcusr.get(ssUserId).subscribe({
        next: (res) => {
          this.user = res;
          if(this.user){
            let pURL = sessionStorage.getItem('pURL');
            this.loggersvc.isLoggedin = true;
            this.loggersvc.user = this.user;
            this.loggersvc.isAdmin = this.user.isAdmin;
            this.loggersvc.userCtrlLevel = this.user.userCtrlLevel;
            this.loggersvc.userId = this.user.id;
            this.loggersvc.username = this.user.userName;
            if(pURL != null){
              this.router.navigate([`${pURL}`])
            }
            else{
              this.router.navigate([`/logging/newlog/${this.user.id}`]);
            }
          }
        }
      })
    }
  }
  updateUserLastLogin() {
    let now = Date.now();
    this.user.dateLastLogin = this.pipe.transform(now, 'yyyy-MM-ddTHH:mm:ss.sss');
    this.svcusr.change(this.user).subscribe({
      next: (res) => {
        console.log("User Updated");
      },
      error: (err) => {
        console.warn(err);
      }
    });
  }
  validateCaptcha() {
    this.recaptchaV3Service.execute('importantAction')
    .subscribe({
      next: (res) => {
        this.loginuser(res);
      },
      error: (err) => {
        this.message = "Recaptcha could not be validated.";
      }
    });
  }
  async loginuser(token){
    var loginCreds: LoginCredentials =  new LoginCredentials();
    loginCreds.token = token
    let password = encrypt(this.loginForm.value.password);
    loginCreds.userName = this.loginForm.value.username;
    loginCreds.password = password;
    this.svcusr.login(loginCreds).subscribe({
      next: (res) => {
        this.user = res
        if(this.user){
          this.loggersvc.isLoggedin = true;
          this.loggersvc.user = this.user;
          this.loggersvc.userId = this.user.id;
          this.loggersvc.username = this.user.userName;
          this.loggersvc.isAdmin = this.user.isAdmin;
          this.loggersvc.userCtrlLevel = this.user.userCtrlLevel;
          var keyData = this.getDecodedAccessToken(this.user.xApiKey.toString());
          sessionStorage.setItem('TokenExp', keyData.exp);
          sessionStorage.setItem('XApiKey', this.user.xApiKey.toString());
          sessionStorage.setItem('isLoggedIn', 'true');
          sessionStorage.setItem('isAdmin', this.user.isAdmin.toString());
          sessionStorage.setItem('isSubAdmin', this.user.userCtrlLevel);
          sessionStorage.setItem('userId', this.user.id.toString());
          sessionStorage.setItem('qrze', this.user.qrzLookupEnabled.toString());
          if(this.user.qrzLookupEnabled){
            sessionStorage.setItem('qrzu', this.user.qrzUserName.toString());
            sessionStorage.setItem('qrzp', this.user.qrzPassword.toString());
          }
          console.log("Login Successful");
          //this.message = "Login Successful";
          //this.updateUserLastLogin();
          this.loginForm.reset();
          let pURL = sessionStorage.getItem('pURL');
          if(pURL != null && pURL != "/logging/newlog/00000000-0000-0000-0000-000000000000"){
            this.router.navigate([`${pURL}`])
          }
          else{
            this.router.navigate([`/logging/newlog/${this.user.id}`]);
          }
          if(this.user.selectedSettingsProfileId != null){
            this.setUserSvc.get(this.user.selectedSettingsProfileId).subscribe({
              next: (res) => {
                sessionStorage.setItem('selectedProfile', JSON.stringify(res));
                this.loggersvc.selectedProfile = res;
              },
              error: (err) => {
                console.error(err);
                if(typeof err.error == "object"){
                  this.message = err.error.title;
                }
                if(typeof err.error == "string"){
                  this.message = err.error;
                }
              }
            });
          }
        }
      },
      error: (err) => {
        console.error(err);
        if(typeof err.error == "object"){
          this.message = err.error.title;
        }
        if(typeof err.error == "string"){
          this.message = err.error;
        }
      }
    })
  }
}
