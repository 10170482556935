import { Component } from '@angular/core';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrl: './documentation.component.css'
})
export class DocumentationComponent {
  supportedRadios = [
    {
      Name: 'Yaesu FT-450D',
      RigName: 'Yaesu - Newer',
      ReadFrequencyCommand: 'FA;',
      ReadModeCommand: 'MD0;',
      ConvertToHex: 'False',
      BaudRate: '38400',
      Parity: 'None',
      StopBits: '2',
      DataBits: '8',
      PowerOption: 'RTS',
      Other: ''
    },
    {
      Name: 'Yaesu FT-950',
      RigName: 'Yaesu - Newer',
      ReadFrequencyCommand: 'FA;',
      ReadModeCommand: 'MD0;',
      ConvertToHex: 'False',
      BaudRate: '4800',
      Parity: 'None',
      StopBits: '2',
      DataBits: '8',
      PowerOption: 'RTS',
      Other: ''
    },
    {
      Name: 'Yaesu FT-1200',
      RigName: 'Yaesu - Newer',
      ReadFrequencyCommand: 'FA;',
      ReadModeCommand: 'MD0;',
      ConvertToHex: 'False',
      BaudRate: '38400',
      Parity: 'None',
      StopBits: '2',
      DataBits: '8',
      PowerOption: 'RTS',
      Other: '(some users report 4800 also works)'
    },
    {
      Name: 'Yaesu FT-2000',
      RigName: 'Yaesu - Newer',
      ReadFrequencyCommand: 'FA;',
      ReadModeCommand: 'MD0;',
      ConvertToHex: 'False',
      BaudRate: '38400',
      Parity: 'None',
      StopBits: '2',
      DataBits: '8',
      PowerOption: 'RTS',
      Other: ''
    },
    {
      Name: 'Yaesu FT-3000',
      RigName: 'Yaesu - Newer',
      ReadFrequencyCommand: 'FA;',
      ReadModeCommand: 'MD0;',
      ConvertToHex: 'False',
      BaudRate: '38400',
      Parity: 'None',
      StopBits: '2',
      DataBits: '8',
      PowerOption: 'RTS',
      Other: '(you can use VS; to return the active VFO)'
    },
    // A user reported the following codes can be used in the phone F key set up form for firing the Yaesu 3000 rig voice memories:
    // RI:PB01;
    // RI:PB02;
    // RI:PB03;
    // RI:PB04;
    // RI:PB05;
    {
      Name: 'Yaesu FT-5000',
      RigName: 'Yaesu - Newer',
      ReadFrequencyCommand: 'FA;',
      ReadModeCommand: 'MD0;',
      ConvertToHex: 'False',
      BaudRate: '38400',
      Parity: 'None',
      StopBits: '2',
      DataBits: '8',
      PowerOption: 'RTS',
      Other: ''
    },
    {
      Name: 'Yaesu FT-9000',
      RigName: 'Yaesu - Newer',
      ReadFrequencyCommand: 'FA;',
      ReadModeCommand: 'MD0;',
      ConvertToHex: 'False',
      BaudRate: '19200',
      Parity: 'None',
      StopBits: '2',
      DataBits: '8',
      PowerOption: 'RTS',
      Other: ''
    },
    {
      Name: 'Yaesu FT-1000MP',
      RigName: 'Yaesu 1000MP',
      ReadFrequencyCommand: '00 00 00 02 10',
      ReadModeCommand: '',
      ConvertToHex: 'True',
      BaudRate: '4800',
      Parity: 'None',
      StopBits: '2',
      DataBits: '8',
      PowerOption: 'RTS',
      Other: ''
    }];
    
  }
  