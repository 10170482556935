<app-menu></app-menu>
<div *ngIf="!isValidated && !submissionCompleted" class="container h-100">
    <div class="row h-100">
        <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
            <div class="d-table-cell align-middle">
                <div class="text-center mt-4">
                    <h1 class="h2">Login to finish your account setup!</h1>
                    <p class="lead">
                        Login with the Username and Password you provided previously to complete your account setup.
                    </p>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="m-sm-4">
                            <form #resetForm [formGroup]="validateUserForm" (ngSubmit)="validateCaptcha()">
                                <div class="form-group">
                                    <label>Username*</label>
                                    <span *ngIf="username.errors?.['required'] && username.touched"
                                        class="warn-message"> - Field is Required!</span><br>
                                    <input class="form-control form-control-lg" formControlName="username" required
                                        type="username" name="username" placeholder="Enter your Username"
                                        autocomplete="username">
                                </div>
                                <div class="form-group">
                                    <label>Password*</label>
                                    <span *ngIf="password.errors?.['required'] && password.touched"
                                        class="warn-message"> - Field is Required!</span><br>
                                    <input class="form-control form-control-lg" formControlName="password"
                                        type="password" name="password" placeholder="Enter your password"
                                        autocomplete="password">
                                    <span class="warn-message">{{errorMessage}}</span><br>
                                </div>
                                <button type="submit" class="btn btn-primary"
                                    [disabled]="!validateUserForm.valid">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isValidated && !submissionCompleted" class="container h-100">
    <div class="row h-100">
        <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
            <div class="d-table-cell align-middle">
                <div class="text-center mt-4">
                    <h1 class="h2">Enter your information in the fields below to complete your new account.</h1>
                    <p class="lead">
                        After completing the form below your account will be pending approval from one of our
                        administrators. Once your account has been approved you'll receive an email confirming that your
                        account has been activated.
                    </p>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="m-sm-4">
                            <form #resetForm [formGroup]="newUserForm" (ngSubmit)="validateCaptcha()">
                                <div class="form-group">
                                    <label>First Name*</label>
                                    <span *ngIf="firstname.errors?.['required'] && firstname.touched"
                                        class="warn-message"> - Field is Required!</span><br>
                                    <input class="form-control form-control-lg" formControlName="firstname" required
                                        type="text" name="firstname" placeholder="Enter your First Name"
                                        autocomplete="given-name">
                                </div>
                                <div class="form-group">
                                    <label>Last Name*</label>
                                    <span *ngIf="lastname.errors?.['required'] && lastname.touched"
                                        class="warn-message"> - Field is Required!</span><br>
                                    <input class="form-control form-control-lg" formControlName="lastname" type="text"
                                        name="lastname" placeholder="Enter your Last Name" autocomplete="family-name">
                                </div>
                                <div class="form-group">
                                    <label>Address*</label>
                                    <span *ngIf="address.errors?.['required'] && address.touched" class="warn-message">
                                        - Field is Required!</span><br>
                                    <input class="form-control form-control-lg" formControlName="address" type="text"
                                        name="address" placeholder="Enter your Street Address"
                                        autocomplete="home street-address address-line1">
                                </div>
                                <div class="form-group">
                                    <label>Address Two</label>
                                    <span *ngIf="address2.errors?.['required'] && address2.touched" class="warn-message">
                                        - Field is Required!</span><br>
                                    <input class="form-control form-control-lg" formControlName="address2" type="text"
                                        name="address2" placeholder="Street Address Line Two"
                                        autocomplete="home street-address address-line2">
                                </div>
                                <div class="form-group">
                                    <label>City*</label>
                                    <span *ngIf="city.errors?.['required'] && city.touched" class="warn-message"> -
                                        Field is Required!</span><br>
                                    <input class="form-control form-control-lg" formControlName="city" type="text"
                                        name="city" placeholder="Enter your City" autocomplete="home city">
                                </div>
                                <div class="form-group">
                                    <label>State*</label>
                                    <span *ngIf="state.errors?.['required'] && state.touched" class="warn-message"> -
                                        Field is Required!</span><br>
                                    <select class="form-control form-control-lg" formControlName="state"
                                        name="state" placeholder="Select your State" autocomplete="home state">
                                        <option value="" selected="selected">Select a State</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>Postal Code*</label>
                                    <span *ngIf="postalCode.errors?.['required'] && postalCode.touched" class="warn-message"> -
                                        Field is Required!</span><br>
                                    <input class="form-control form-control-lg" formControlName="postalCode" type="text"
                                        name="postalCode" placeholder="Enter your Zip Code" autocomplete="postal-code">
                                </div>
                                <div class="form-group">
                                    <label>Country*</label>
                                    <span *ngIf="country.errors?.['required'] && country.touched" class="warn-message"> -
                                        Field is Required!</span><br>
                                    <input class="form-control form-control-lg" formControlName="country" type="text"
                                        name="country" placeholder="Enter your Country" autocomplete="country-name">
                                        <span class="warn-message">{{errorMessage}}</span><br>
                                </div>
                                <button type="submit" class="btn btn-primary"
                                    [disabled]="!validateUserForm.valid">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="submissionCompleted" class="container h-100">
    <div class="row h-100">
      <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
        <div class="d-table-cell align-middle">
          <div class="text-center mt-4">
            <h1 class="h2">Your new account has been successfully created!</h1>
            <p class="lead">
              An email will be sent to the address you provided as soon as your account has been approved by an administrator. (ussually within 24hours) For assistance please contact support at <a href="mailto:support@zjtlabs.com">support&#64;zjtlabs.com</a>
            </p>
          </div>
          <div class="card">
            <div class="card-body text-center">
              <div clas="m-sm-4">
                <a class="btn btn-primary" href="/login">LOGIN</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>