<app-menu></app-menu>

<!-- <form [formGroup]="resetForm" (ngSubmit)="validateCaptcha()">
    <input class="form-control form-control-lg" formControlName="username" required type="username"
    name="username" placeholder="Enter your Username">
    <input class="form-control form-control-lg" formControlName="email" required type="email" name="email" placeholder="Enter your Email Address">
    <button type="submit" class="btn btn-primary">Submit</button>
</form> -->


<div *ngIf="routeParam == 'reset' " class="container h-100">
    <div class="row h-100">
      <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
        <div class="d-table-cell align-middle">
          <div class="text-center mt-4">
            <h1 class="h2">Forgot your password?</h1>
            <p class="lead">
              Enter your information below to request a reset email.
            </p>
          </div>
          <div class="card">
            <div class="card-body">
              <div *ngIf="!submissionCompleted && routeParam == 'reset'" class="m-sm-4">
                <form [formGroup]="resetForm" (ngSubmit)="validateCaptcha()">
                    <div class="form-group">
                    <label>Username*</label>
                    <input class="form-control form-control-lg" formControlName="username" required type="username"
                      name="username" placeholder="Enter your Username" autocomplete="username">
                  </div>
                  <div class="form-group">
                    <label>Email*</label>
                    <input class="form-control form-control-lg" formControlName="email" required type="email" name="email" placeholder="Enter your Email Address" autocomplete="email">
                    <span class="warn-message">{{message}}</span><br>
                  </div>
                  <button type="submit" class="btn btn-primary btnsubmit" [disabled]="!resetForm.valid || buttonDisable">Submit</button>
                </form>
              </div>
              <div *ngIf="submissionCompleted && routeParam == 'reset'" class="text-center">
                <h4>An email has been sent to the email address you provided.</h4><br>
                <h5>Please follow the link contained in the email to reset your password.</h5>
                <h6><a href="/login">Return to the login page to sign in!</a></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="routeParam == 'signup' " class="container h-100">
    <div class="row h-100">
      <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
        <div class="d-table-cell align-middle">
          <div class="text-center mt-4">
            <h1 class="h2">Create a New User!</h1>
            <p class="lead">
              Enter your information below to request a new user account.
            </p>
          </div>
          <div class="card">
            <div class="card-body">
              <div *ngIf="!submissionCompleted && routeParam == 'signup'" class="m-sm-4">
                <form [formGroup]="newUserRequestForm" (ngSubmit)="validateCaptcha()">
                  <div class="form-group">
                    <label>Email*</label>
                    <span *ngIf="email.errors?.['required'] && email.touched" class="warn-message"> - Field is Required!</span><br>
                    <input class="form-control form-control-lg" formControlName="email" required type="email" name="email" placeholder="Enter your Email Address" autocomplete="email">
                  </div>
                  <div class="form-group">
                    <label>Callsign*</label>
                    <span *ngIf="callsign.errors?.['required'] && callsign.touched" class="warn-message"> - Field is Required!</span><br>
                    <input class="form-control form-control-lg" formControlName="callsign" required type="callsign" name="callsign" placeholder="Enter your Callsign" autocomplete="callsign">
                  </div>
                  <div class="form-group">
                    <label>Username*</label>
                    <span *ngIf="username.errors?.['required'] && username.touched" class="warn-message"> - Field is Required!</span><br>
                    <input class="form-control form-control-lg" formControlName="username" required type="username"
                      name="username" placeholder="Enter a New Username" autocomplete="username">
                  </div>
                  <div class="form-group">
                    <label>Password*</label>
                    <span *ngIf="password1.errors?.['required'] && password1.touched" class="warn-message"> - Field is Required!</span><br>
                    <input class="form-control form-control-lg" formControlName="password1" required type="password" name="password1" placeholder="Enter a New Password" autocomplete="password">
                  </div>
                  <div class="form-group">
                    <label>Confirm Password*</label>
                    <span *ngIf="password2.errors?.['required'] && password2.touched" class="warn-message"> - Field is Required!</span><br>
                    <input class="form-control form-control-lg" formControlName="password2" required type="password" name="password2" placeholder="Confirm your New Password" autocomplete="confirm-password">
                  </div>
                  <span *ngIf="newUserRequestForm.errors?.['PasswordNoMatch'] && password1.touched && password2.touched" class="warn-message">Passwords Must Match!</span><br>
                  <ul *ngIf="password1.errors?.['pattern'] && password1.touched" class="warn-message">
                      Password Requirements: 
                      <li>Minimum Length is 8 Characters</li>
                      <li>Must Contain at Least 1 Uppercase Letter</li>
                      <li>Must Contain at Least 1 Lowercase Letter</li>
                      <li>Must Contain at Least 1 Number</li>
                      <li>Must Contain at Least 1 Special Character</li>
                  </ul>
                  <span class="warn-message">{{message}}</span><br>

                  <button type="submit" class="btn btn-primary btnsubmit" [disabled]="!newUserRequestForm.valid || buttonDisable">Submit</button>
                </form>
              </div>
              <div *ngIf="submissionCompleted && routeParam == 'signup'" class="text-center">
                <h4>An email has been sent to the email address you provided.</h4><br>
                <h5>Please follow the link contained in the email within 24 hours to verify, and complete the setup of your new account.</h5>
                <h6><a href="/login">Return to the login page to sign in!</a></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
