export class NewsItem {
    id: string = "00000000-0000-0000-0000-000000000000";
    title: string = "";
    description: string = "";
    news: string = "";
    embededLink: string = "";
    fontColor: string = "";
    dateCreated: string | Date | null = null;
    dateModified: string | Date | null = null;
    dateStart: string | Date | null = null;
    dateEnd: string | Date | null = null;
}