import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from './user.class';
import { Observable, of, tap } from 'rxjs';
import { LoggerService } from './logger.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { LoginCredentials } from './logincredentials.class';
import { NewUserForm, NewUserRequestForm, NewUserValidate, PasswordResetRequest, PasswordResetSubmitData } from './requests.class';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  // baseurl: string = 'http://localhost:5000/api/users';
  baseurl: string = environment.CalllogAPIurl + '/api/users';
  appkey: string = environment.AppKey;

  constructor(
    private http: HttpClient,
    private readonly loggerService: LoggerService,
    private router: Router

  ) { }

  list(): Observable<User[]> {
    return this.http.get(`${this.baseurl}`, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<User[]>;
  }
  get(id: string): Observable<User> {
    return this.http.get(`${this.baseurl}/${id}`, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<User>;
  }
  renew(id: string): Observable<User> {
    return this.http.get(`${this.baseurl}/renew/${id}`, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<User>;
  }
  // This method starts the handshake to receive the apikey and therefor does not require auth
  login(creds: LoginCredentials): Observable<User> {
    return this.http.post(`${this.baseurl}/login`, creds, { headers: { ['XAppKey']: `${this.appkey}` } }) as Observable<User>;
  }
  passwordResetRequest(data: PasswordResetRequest): Observable<any> {
    return this.http.post(`${this.baseurl}/user/password/reset/request`, data, { headers: { ['XAppKey']: `${this.appkey}` } }) as Observable<any>;
  }
  passwordResetValidate(uid: string): Observable<any> {
    let data = { 'Uid': uid }
    return this.http.post(`${this.baseurl}/user/password/reset/validate`, data, { headers: { ['XAppKey']: `${this.appkey}` } }) as Observable<any>;
  }
  passwordResetReset(data: PasswordResetSubmitData): Observable<any> {
    return this.http.post(`${this.baseurl}/user/password/reset/reset`, data, { headers: { ['XAppKey']: `${this.appkey}` } }) as Observable<any>;
  }
  newUserRequest(data: NewUserRequestForm): Observable<any> {
    return this.http.post(`${this.baseurl}/user/new/user/request`, data, { headers: { ['XAppKey']: `${this.appkey}` } }) as Observable<any>;
  }
  newUserValidate(data: NewUserValidate): Observable<any> {
    return this.http.post(`${this.baseurl}/user/new/user/validate`, data, { headers: { ['XAppKey']: `${this.appkey}` } }) as Observable<any>;
  }
  newUserComplete(data: NewUserForm): Observable<any> {
    return this.http.post(`${this.baseurl}/user/new/user/complete`, data, { headers: { ['XAppKey']: `${this.appkey}` } }) as Observable<any>;
  }
  updateUserStatus(data): Observable<any> {
    return this.http.post(`${this.baseurl}/user/actions/activate`, data, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
  }
  create(user: User): Observable<User> {
    return this.http.post(`${this.baseurl}`, user, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<User>;
  }
  change(user: User): Observable<any> {
    return this.http.put(`${this.baseurl}/${user.id}`, user, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
  }
  remove(id: string): Observable<any> {
    return this.http.delete(`${this.baseurl}/${id}`, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
  }
  getUploadProgress(id: string):Observable<any> {
    return this.http.get(`${this.baseurl}/upload/progress/${id}`, { headers: { ['XApiKey']: `${sessionStorage.getItem('XApiKey')}` } }) as Observable<any>;
  }
  isLoggedIn() {
    return of(this.loggerService.isLoggedin).pipe(tap((v) => console.log(v)));
  }
  logout(): void {
    sessionStorage.clear();
    this.loggerService.isLoggedin = false;
    this.router.navigate(["login"])
  }


  encryptData(data) {

    try {
      return CryptoJS.AES.encrypt(JSON.stringify({data}), this.appkey).toString();
    } catch (e) {
      console.log(e);
    }
  }

  decryptData(data) {

    try {
      const bytes = CryptoJS.AES.decrypt(data, this.appkey);
      if (bytes.toString()) {
        let obj = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return obj.data;
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

}
