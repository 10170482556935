<app-menu></app-menu>
<div style="margin: 10px;">
    <h2>Space Weather</h2>
    <img class="pop" id="spaceWeather" style="margin: 10px;" src="https://www.hamqsl.com/solar101vhfpic.php">
</div>
<div style="margin: 10px;">
    <h2>Current Location Weather</h2>
</div>
<table>
    <tr>
        <th>
            City:
        </th>
        <td>
            {{city}}
        </td>
    </tr>
    <tr>
        <th>
            State:
        </th>
        <td>
            {{state}}
        </td>
    </tr>
    <tr>
        <th>
            Temp:
        </th>
        <td>
            {{tempCurrentHour}}&deg;F
        </td>
    </tr>
    <tr>
        <th>
            Elevation: 
        </th>
        <td>
            {{elevation}}m
        </td>
    </tr>
</table>
<ejs-grid [dataSource]="hourlyPeriods" height=300 [allowResizing]='true' [enableVirtualization]="false" [pageSettings]="options">
 <e-columns>
    <e-column field='startTime' headerText='Date' width=55 textAlign='center' [allowResizing]="false" [format]="dateFormatOptions" type="date"></e-column>
    <e-column field='startTime' headerText='Time' width=55 textAlign='center' [allowResizing]="false" [format]="timeFormatOptions" type="date"></e-column>
    <e-column field='temperature' headerText='T(&deg;F)' width='40' textAlign='center'></e-column>
    <e-column field='probabilityOfPrecipitation.value' headerText='Rain(%)' width='50' textAlign='center'></e-column>
    <e-column headerText='' width='60' minWidth="60" maxWidth="60" textAlign='Center'>
        <ng-template #template let-data>
            <div class="tableimage">
                <img class="weatherPic" src="{{data.icon}}" alt="{{data.EmployeeID}}"/>
            </div>
        </ng-template>
    </e-column>
    <e-column field='shortForecast' headerText='Forecast' width='250' textAlign='center'></e-column>
    <e-column field='relativeHumidity.value' headerText='RH(%)' width='50' textAlign='center'></e-column>
    <e-column field='dewpoint.value' headerText='DP(&deg;C)' width='60' textAlign='center' format="N2"></e-column>
    <e-column field='windSpeed' headerText='WS' width='60' textAlign='center'></e-column>
    <e-column field='windDirection' headerText='WD' width='60' textAlign='center'></e-column>
 </e-columns>
</ejs-grid>

<div class="modal fade" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">              
        <div class="modal-body">
            <button  style="margin: 10px;" type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal">Close</button>
          <img src="" class="imagepreview" style="width: 100%; height: auto;" >
        </div>
      </div>
    </div>
  </div>
  

