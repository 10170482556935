    <!-- Modal -->

    <div class="modal fade" id="TimeOutModal" tabindex="-1" aria-labelledby="TimeOutModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <span *ngIf="minutesR <= 9">
                        <h5 class="modal-title" id="TimeOutModalLabel">Your Session Will Expire Soon!</h5>
                    </span>
                    <span *ngIf="minutesR > 9">
                        <h5 class="modal-title" id="TimeOutModalLabel">Session Timer</h5>
                    </span>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <span *ngIf="minutesR <= 9">
                        <h5>Action must be taken or you will automatically be logged out.</h5>
                    </span>
                    <span *ngIf="minutesR > 9">
                        <h5>Session is only renewable within one hour of expiration.</h5>
                    </span>
                    <h4>
                        <span *ngIf="hoursR> 0">
                            {{hoursR}} Hours 
                        </span>
                        {{minutesR}} Minutes {{secondsR}} Seconds
                    </h4>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" id="renewBtn" title="Disabled while time remaining is greater than one hour." [disabled]="hoursR >= 1" (click)="renewUser()">Renew</button>
                    <button class="btn btn-danger" (click)="logoutNow()">Logout</button>
                </div>
            </div>
        </div>
    </div>

