<app-menu></app-menu>
<div style="margin:20px;">
    <h2>W8ZJT Amateur Contact Logging Application V2.5.01</h2>
    <h3>Version 2.5 Description</h3>
    Enhances the Hamlogs grid with filtering sorting and searching, Export pdf or xlxs functionality. More security with the addition of google recaptcha and secure per user api keys.
    <h4>Version Detials <--- 2.5.01 ---></h4>
    <ul>
        <li>Google Recaptch implimentation enhances form security</li>
        <li>Data transfers change to "small query" format - signifigantly enhanced speed and user expirience.</li>
        <li>Reset password utility</li>
        <li>Create a new user utility</li>
        <li>Email Notifications</li>
    </ul>
    <h3>Version 2.0 Description:</h3>
    W8ZJT Amateur Contact Log is a Web Application designed and built by Zachary Tumbusch - W8ZJT, for the intended purpose of logging Amateur Radio and Shortwave HF contacts in a precise, convinient and consistant way.
    W8ZJT Calllog began as a hobbiest project while I was attending school and has slowly become a highly usable and efficient way to log Amateur contacts.
    Why choose a web application platform? The Answer is simple security and versitility. W8ZJT Calllog is accessible from anywhere in the world, all you need is a computer or mobile device and an internet connection.
    The ability to import / export and keep your data safe.

    <h4>Version Details:</h4>
    <ul>
        <li>QRZ search interface incorperated with FCC US database and previous logs.</li>
        <li>Import ADIF file type log book backups with by date duplicate detection - post processor built and tested using N3FJP export.</li>
        <li>Guid based identifiers for all users, profiles, and logs.</li>
        <li>Selectable user profiles for enhanced logbook expirience and purpose oriented usage.</li>
        <li>New more versitile input form using flex.</li>
        <li>Syncfusion filterable and sortable tables for Hamlog List.</li>
        <li>Database overhaul seperating fcc and dxspots data context.</li>
        <li>Api framework using Microsoft .Net Core 8</li>
    </ul>
    <h4>Change Log:</h4>
    <ul>
        <li>Date: 05-28-24
            <ul>
                <li>
                    Enhancment - Added drop down list fuctionallity for net / contest field. This pulls a unique list of data from the pre-existing hamlogs collection.
                </li>
                <li>
                    Bugfix - table pagination is now recoverable during net/rt mode as well as show previous logs operation.
                </li>
            </ul>
        </li>
        <li>Date: 03-05-2024
            <ul>
                <li>
                    Enhancment - NET / ROUND-Table functionality added
                </li>
                <li>
                    Comments will carry over from the database for existing entries with comments.
                </li>
            </ul>
        </li>
        <li>Date: 03-02-2024
            <ul>
                <li>
                    Rebuild menu bar using bootstrap framework.<br>
                    <span style="font-weight: bold;">Description:</span>
                    Implimentation of bootstrap menu bar with collapse for smaller screens.
                </li>
                <li>
                    Upgrade to angular 17.
                </li>
                <li>
                    NPM jQuery package installed to project.
                </li>
                <li>
                    NPM Popper package installed to project.
                </li>
                <li>
                    Disable clearing the callsign feild.<br>
                    <span style="font-weight: bold;">Description:</span>
                    This will allow the user to edit an already entered callsign without disrupting the entry flow.
                </li>
                <li>
                    User profile setting added allowing the ability to disable the previously worked calllog table during the search operation. This feature will remain enabled by default.
                </li>
                <li>
                    Display width to 100%
                </li>
                <li>
                    Changed the lable for "Current Time" to "Local".
                </li>
            </ul>
        </li>
        <li>Date: 02-24-2024
            <ul>
                <li>
                    Allow user to enter their own QRZ username and password as well as enable or disable the service without administrator intervention.
                </li>
                <li>
                    Fix unsubscribed QRZ user bug "This was a typo when replacing the searched callsign with the retreived callsign".
                </li>
                <li>
                    Update framework to latest packages based on vulnarability database.
                </li>
                <li>
                    Include packages for live serial data communication.<br>
                    <span style="font-weight: bold;">Description:</span>
                    This will be implimented in a future release for incorperation of rig control.
                </li>
            </ul>
        </li>
        <li>Date: 02-18-2024
            <ul>
                <li>
                    Fixed zero's in the url bar shown for user id on first login.
                </li>
                <li>
                    Added clickable button into callsign field for quick access to main editing screen.
                </li>
            </ul>

        </li>
        <li>Date: 02-13-2024
            <ul>
                <li>
                    Fix for secondary sort order based on the date and time the log was created. Example: multiple logs sharing the same value in time on for base level sorting.
                    However the date created will retain the list in the order that they were entered into the logbook.
                </li>
                <li>
                    Table modal weirdness related to paging is now fixed. opening the modal while view logs on older pages will not cause the table to loose it's paging functionality.
                </li>
                <li>
                    Make updates to QRZ object so that the entered call does not get overwritten when the qso-ee is not using their fcc valid callsign and instead using an alias. 
                    The QRZ recommended callsign will now be stored in the reciprical field.
                </li>
                <li>
                    The profile setting for number of logs to show will now be updated when the drop down list "items per page" is updated. This option is carried over to the user profile.
                </li>
            </ul>

        </li>
        <li>Date: 02-12-2024
            <ul>
                <li>
                    Added number of calls to show into user profile.<br>
                    <span style="font-weight: bold;">Description:</span>
                    You can now set the number of calls shown on on the main hamlog list display for each user profile.
                </li>
            </ul>
        </li>
        <li>Date: 02-11-2024
            <ul>
                <li>Added escape key functionality to main logging application.<br>
                    <span style="font-weight: bold;">Description:</span>
                    Pressing the escape key, at any time during entry of a new contact log, will result in a reset of the input form as well as direct tab index focus to the callsign field.
                </li>
                <li>
                    Added enter key functionality to main logging application.<br>
                    <span style="font-weight: bold;">Description:</span>
                    Pressing the enter key, at any time during the entry of a new contact log, will result in a submission of the currently entered / auto populated data. 
                    The enter key will not submit the current log while the comments field is in focus. as soon as the comments field is no longer in focus the enter button will resume normal submission operation.
                    The enter key will also be disabled if the callsign field has not yet expirienced a blur operation.
                </li>
                <li>
                    Adjust TabIndex to skip auto filled fields on main calllog entry form.<br>
                    <span style="font-weight: bold;">Description:</span>
                    Pressing the tab key will index through the form skipping date/time inputs.
                </li>
                <li>
                    Default user profile is not included in the creation of a new user.<br>
                    <span style="font-weight: bold;">Description:</span>
                    Every user must have at least one always active profile. This is accomplished by the use of a permanent "Default Profile"<br>
                    The settings related to this profile are editable but the name of the profile will always remain "Default Profile".<br>
                    Default profile is not deletable and cannot be renamed. Users can however create their own custom profile along side of the default profile.
                </li>
                <li>
                    About page / Change Log<br>
                    <span style="font-weight: bold;">Description:</span>
                    The about page will be used to keep a detailed log of application changes and updates. This page is only available to active users.<br>
                    This will be the best place to come to keep track of the most recent enhancments upgrades and updates.
                </li>
            </ul>
        </li>
    </ul>
    <h4>Credits:</h4>
    <ul>
        <li>W8ZJT - Zachary Tumbusch - Software Engineer - Designer of W8ZJT Call Logging Application</li>
        <li>WA8SDF - James Barrie - Quality Assurance Specialist</li>
    </ul>

</div>

