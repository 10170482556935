import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Hamlog } from '../hamlog.class';
import { UserService } from 'src/app/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/user/user.class';
import { LoggerService } from 'src/app/user/logger.service';
import { DatePipe } from '@angular/common';
import { AmateurSearchService } from 'src/app/amateur/amateur-search.service';
import { Amateuren } from 'src/app/amateur/amateuren.class';
import { HamlogService } from '../hamlog.service';
import { FreqencyPipe } from 'src/app/pipes/freqency.pipe';
import { CalcBandPipe } from 'src/app/pipes/calc-band.pipe';
import { FilterSettingsModel, PageSettingsModel, GridComponent, IFilterUI, parentsUntil, Column, PageEventArgs, RowDataBoundEventArgs, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { InfiniteScrollService } from '@syncfusion/ej2-angular-grids';
import { DatePicker } from '@syncfusion/ej2-angular-calendars';
import { DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import { DataManager, UrlAdaptor, Query } from '@syncfusion/ej2-data'
import { QrzService } from 'src/app/user/qrz.service';
import { qrzSubscriber } from 'src/app/user/qrzSubscriber.class';
import { qrzNonSub } from 'src/app/user/qrzNonSubscriber.class';
import { UserSetting } from 'src/app/user/userSetting.class';
import { UserSettingService } from 'src/app/user/user-setting.service';
import { v4 as uuidv4 } from 'uuid';
import { NetlogService } from '../netlog.service';
import { Netlog } from '../netlog.class';
import { ToastComponent } from '@syncfusion/ej2-angular-notifications';
import { UniqueNetLog } from '../uniqueNetlog.class';
import { hasFlag } from 'country-flag-icons';
import { getCountryCode } from '../countrycodes';
import { SerialInterfaceComponent } from 'src/app/rig_interface/serial-interface/serial-interface.component';
import { environment } from 'src/environments/environment';
import { NetPreset } from 'src/app/settings/netpresets/netpreset.class';
import { Bands, Modes, DefaultGuid } from 'src/app/settings/globalVars';



@Component({
  selector: 'app-hamlog-listcreate',
  templateUrl: './hamlog-listcreate.component.html',
  styleUrls: ['./hamlog-listcreate.component.css'],
  providers: [InfiniteScrollService]
})
export class HamlogListcreateComponent {

  public filterSettings?: FilterSettingsModel;
  public selectionOptions?: SelectionSettingsModel;
  public pageSettings?: PageSettingsModel;
  public templateOptionsDropDown?: IFilterUI;
  public templateOptionsDatePicker?: IFilterUI;
  public dateElement?: HTMLInputElement;
  public dropdown?: HTMLElement;
  public dateFormatOptions?: object;
  public timeFormatOptions?: object = { type: "date", format: "h:mm:ss a" };
  public timePickerFormat: string = 'h:mm:ss a';
  public sortOptions?: object;
  public timePickerScrollto: Date = new Date;
  areYouSureModalDelete: boolean = false;
  modalDeleteOrCancel: string = "Delete";
  pageTitle = "HamLogs";
  user!: User;
  qrzEnable: boolean = false;
  selectedUserProfile!: UserSetting;
  allBands: string[] = Bands;
  modeList: string[] = Modes;
  public now: string | null = "";
  public nowGMT: string | null = "";
  qrzSubscriber!: qrzSubscriber;
  qrzNonSub!: qrzNonSub;
  qrzSub: boolean = true;
  qrzNotFound: boolean = false;
  hamlogs: Hamlog[] = [];
  uniqueNetLogs: UniqueNetLog[] = [];
  parsedNetLogs: UniqueNetLog[] = [];
  searchHamLogs: Hamlog[] = [];
  searchResCount: number = 0;
  numberOfCallsToShowPerPage: number = 12;
  searchResMessage: string = "";
  newlog: Hamlog = new Hamlog();
  netlog: Netlog = new Netlog();
  hamEn!: Amateuren;
  isaham: string = "";
  sortColumn: string = "id";
  sortAsc: boolean = false;
  searching: boolean = false;
  logpost: boolean = false;
  showNetColumn: boolean = false;
  showDxSpot: boolean = false;
  pipe = new DatePipe('en-US');
  freqpipe = new FreqencyPipe;
  bandpipe = new CalcBandPipe;
  enteredfreq: string = "";
  warnMessage: string = "";
  warnMessageDesc: string = "";
  successMessage: string = "";
  regExp = /[a-zA-Z]/g;
  tableHeight: string = "";
  intervalId: any;
  enterDisable: boolean = false;
  selectedHamlog: Hamlog = new Hamlog;
  lookupPreviousLogs: boolean = true;
  showLookupPreviousLogs: boolean = false;
  use24Hour: boolean = false;
  logMode: string = "NORMAL";
  logModeOptions: string[] = [];
  isNetRtMode: boolean = false;
  newLogId: string | null = null;
  checkincount: number = 0;
  selectedRecords: Hamlog[] = [];
  selectedRecordsNewTon?: String | Date;
  selectedRecordsNewTonCB: boolean = false;
  selectedRecordsNewToff: String | Date | null = null;
  selectedRecordsNewToffCB: boolean = false;
  selectedRecordsNewNet: string = "";
  selectedRecordsNewNetCB: boolean = false;
  selectedRecordsNewComments: string = "";
  selectedRecordsNewCommentsCB: boolean = false;
  selectedRecordsNewPower: number = 0;
  selectedRecordsNewPowerCB: boolean = false;
  lastNetRt: string = DefaultGuid;
  @ViewChild('aForm') aForm!: ElementRef;
  @ViewChild('grid') public grid?: GridComponent;
  @ViewChild('toastMessageBox') public toastMessageBox?: ToastComponent;
  @ViewChild('rigcontrol') public rigControl?: SerialInterfaceComponent;
  public toastMessagePosition = { X: 'Right', Y: 'Top' };
  toastMessageContent: string = "";
  toastMessageTitle: string = "";
  serialPort: any;
  rowSelectCancel: boolean = false;
  public hamlogDataManager?: DataManager;
  public emptyDataSet: any[] = [];
  showEmpty: boolean = false;



  constructor(
    private urssvc: UserService,
    private route: ActivatedRoute,
    private amasvc: AmateurSearchService,
    private hamsvc: HamlogService,
    private sys: LoggerService,
    private userSetSvc: UserSettingService,
    private router: Router,
    private qrz: QrzService,
    private netLogSvc: NetlogService,
  ) {}

  @HostListener('document:keyup', ['$event'])
  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.clear();
      this.setFocus('callsign');
    }
    if(event.key === 'Enter' && !this.enterDisable) {
      this.freqBlur();
      this.save();
    }
  }
  callSignButtonOpen(data: Hamlog){
    this.router.navigateByUrl(`/hamlog/update/${data.id}`);
  }
  setFocus(name) {
    const ele = this.aForm.nativeElement[name];
    if (ele) {
      ele.focus();
    }
  }
  enterKeyDisable(disable: boolean) {
    if(disable){
      this.enterDisable = true;
    }
    else{
      this.enterDisable = false;
    }
  }
  openSessionTimeoutModal() {
    ($('#TimeOutModal') as any).modal('show');
  }

  saveModalEdits(): void {
    let modalEditedHamlogToPost = new Hamlog;
    modalEditedHamlogToPost = this.selectedHamlog;
    let tOnDateObject = modalEditedHamlogToPost.dateAndTime as Date;
    let tOffDateObject = modalEditedHamlogToPost.timeOff as Date;
    tOffDateObject.setFullYear(tOnDateObject.getFullYear());
    tOffDateObject.setMonth(tOnDateObject.getMonth());
    tOffDateObject.setDate(tOnDateObject.getDate());
    modalEditedHamlogToPost.dateAndTime = this.pipe.transform(tOnDateObject, 'yyyy-MM-ddTHH:mm:ss.sss');
    modalEditedHamlogToPost.timeOff = this.pipe.transform(tOffDateObject, 'yyyy-MM-ddTHH:mm:ss.sss');
    modalEditedHamlogToPost.callsign = modalEditedHamlogToPost.callsign.toUpperCase();
    this.hamsvc.change(modalEditedHamlogToPost).subscribe({
      next: (res) => {
        console.debug("Log Updated!");
        this.grid?.refresh();
      },
      error: (err) => {
        console.error(err);
      }
    })
  }
  initJqueryListener(): void {
    $(document).on("hide.bs.modal", () => {
      this.onModalClose();
    });
  }

  onModalClose(): void {
    if(this.areYouSureModalDelete){
      this.deleteFromModal();
    }
  }

  deleteFromModal(): void {
    this.areYouSureModalDelete = !this.areYouSureModalDelete;
    this.modalDeleteOrCancel = !this.areYouSureModalDelete ? "Delete" : "Cancel?";
  }
  deleteFromModalVerified(): void {
    this.hamsvc.remove(this.selectedHamlog.id).subscribe({
      next: (res) => {
        this.user.recordCount -= 1;
        console.debug("Deleted!");
        this.grid?.refresh();
        if(this.isNetRtMode){
          this.setNetRtCheckinCount();
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }


  selectColumn(col: string): void {
    if (col === this.sortColumn) {
      this.sortAsc = !this.sortAsc;
      return;
    }
    this.sortAsc = true;
    this.sortColumn = col;
  }

  save(): void {
    if(this.newlog.callsign == ""){
      console.warn("The Callsign Does not have to be a callsign but cannot be blank.");
      this.warnMessage = "Callsign field CANNOT be blank.";
      this.warnMessageDesc = "Please Enter a Callsign"
      this.setFocus('callsign');
      this.toast("Warning!", "Callsign field CANNOT be blank!", "toastRed");
      return;
    }
    if (this.regExp.test(this.enteredfreq)) {
      console.warn("The entered frequency cannot contain any letters")
      this.warnMessage = "The entered frequency is INVALID : ";
      this.warnMessageDesc = "Frequency field can NOT contain letters";
      this.setFocus('frequency');
      this.toast("Warning!", "Frequency field can NOT contain letters!", "toastRed");

      return;
    }
    if (!this.enteredfreq.includes(".")) {
      console.warn("The entered frequency must contain a decimal point for mhz");
      this.warnMessage = "The entered frequency is INVALID : ";
      this.warnMessageDesc = "Must contain a decimal point after MHZ";
      this.setFocus('frequency');
      this.toast("Warning!", "Must contain a decimal point after MHZ!", "toastRed");
      return;
    }
    
    this.newlog.frequency = this.freqpipe.transform(this.enteredfreq);
    //set the off date and time for the log
    let offDate = Date.now();
    this.newlog.timeOff = this.pipe.transform(offDate, 'yyyy-MM-ddTHH:mm:ss.sss');
    let newDT = this.newlog.dateAndTime;
    this.newlog.dateAndTime = this.pipe.transform(newDT, 'yyyy-MM-ddTHH:mm:ss.sss');
    this.user.lastWorkedMode = this.newlog.mode;
    this.user.lastWorkedPower = this.newlog.power;
    this.user.recordCount += 1;
    this.hamsvc.create(this.newlog).subscribe({
      next: (res) => {
        this.successMessage = "Entry Successful";
        this.toast("Success!", "Log entry added successfully!", "toastGreen");
        this.logpost = true;
        this.newlog.fullName = "";
        this.newlog.firstName = "";
        this.newlog.lastName = "";
        this.newlog.address = "";
        this.newlog.city = "";
        this.newlog.state = "";
        this.newlog.postalCode = "";
        this.newlog.fccId = "";
        this.newlog.callsign = "";
        if(!this.isNetRtMode){
          this.newlog.qth = "";
        }
        this.newlog.comments = "";
        if(this.isNetRtMode){
          this.netlog.hamLogId = res.id;
          let id = this.selectedUserProfile.lastNetRt;
          this.netlog.netRtId = id;
          this.netlog.netName = this.newlog.qth;
          this.netLogSvc.create(this.netlog).subscribe({
            next:(res) => {
              console.log("created sub netrt row");
              this.netlog.oneAndDone = false;
              this.setNetRtCheckinCount();
            },
            error:(err) => {
              console.log(err);
            }
          });
        }

        console.debug(res, "Log Created!");
        this.urssvc.change(this.user).subscribe({
          next: (res) => {
            console.debug("User Updated");
            //After a new log has been added and user has been updated.
            this.grid?.refresh();
          },
          error: (err) => { console.error(err); }
        });

        this.searching = true;
      },
      error: (err) => {
        console.error(err);
      }
    });
    this.setFocus('callsign');
  }
  setNetRtCheckinCount() {
    this.hamlogDataManager?.executeQuery(new Query(), (data) => {
      let logs = (data.actual.result as Hamlog[]);
      this.checkincount = logs.length;
      if(this.checkincount > 0){
        this.newlog.qth = logs[0].qth;
        this.enteredfreq = logs[0].frequency;
        this.newlog.band = logs[0].band;
      }
    });
  }
  clear(clearCallsign: boolean = true): void {
    this.clearAllSearchFilters();
    if(clearCallsign){
      this.newlog.callsign = "";
    }
    this.newlog.country = "";
    this.newlog.city = "";
    this.newlog.state = "";
    this.newlog.postalCode = "";
    this.newlog.fullName = "";
    this.newlog.firstName = "";
    this.newlog.lastName = "";
    this.isaham = "";
    this.successMessage = "";
    this.qrzNotFound = false;
    this.logpost = false;
    this.searching = true;
    this.enterDisable = true;
  }
  freqChange(): void {
    this.warnMessage = "";
    this.warnMessageDesc = "";
  }
  freqBlur(): void {
    if (this.enteredfreq === "" || !this.enteredfreq.includes(".")) {
      console.warn("cannot automatically calculate band");
      return;
    } else {
      this.newlog.band = this.bandpipe.transform(this.enteredfreq).toString();
    }
    if (this.newlog.band.toString() === "0") {
      this.warnMessage = "Caution: Out of band! ShortWave!";
    }
    console.log(this.newlog.band);
    this.enterDisable = false;
  }
  searchQRZ(): void {
    if (sessionStorage.getItem('qrze') == 'true') {
      this.qrz.searchQrz(this.newlog.callsign).subscribe({
        next: (res) => {
          if (res.QRZDatabase.Session.Error === `Not found: ${this.newlog.callsign.toUpperCase()}`) {
            this.qrzNotFound = true;
            console.warn("QRZ " + res.QRZDatabase.Session.Error);
            return;
          }
          if (res.QRZDatabase.Session != null) {
            if (res.QRZDatabase.Session.Error != null) {
              this.qrz.qrzLogin(sessionStorage.getItem('qrzu'), sessionStorage.getItem('qrzp')).subscribe({
                next: (res) => {
                  if (res.QRZDatabase.Session.Error != null) {
                    return;
                  }
                  else {
                    if (res.QRZDatabase.Session != null) {
                      sessionStorage.setItem('qrzSessionKey', res.QRZDatabase.Session.Key);
                      this.qrz.searchQrz(this.newlog.callsign).subscribe({
                        next: (res) => {
                          if (res.QRZDatabase.Session.Error != null) {
                            if (res.QRZDatabase.Session.Error == `Not found: ${this.newlog.callsign}`) {
                              console.warn("QRZ " + res.QRZDatabase.Session.Error);
                              this.qrzNotFound = true;
                              return;
                            }
                          }
                          else {
                            if (res.QRZDatabase.Session.SubExp == "non-subscriber") {
                              console.log("QRZ-2 nonsub DATA SET");
                              this.qrzSub = false;
                              this.qrzNotFound = false;
                              this.qrzNonSub = res.QRZDatabase.Callsign;
                              //this.newlog.callsign = this.qrzNonSub.call;
                              this.newlog.city = this.qrzNonSub.addr2;
                              this.newlog.country = this.qrzNonSub.country;
                              this.newlog.firstName = this.qrzNonSub.fname;
                              this.newlog.fullName = this.qrzNonSub.fname + " " + this.qrzNonSub.name;
                              this.newlog.lastName = this.qrzNonSub.name;
                              this.newlog.state = this.qrzNonSub.state;
                              this.newlog.lookupSource = "QRZ";
                            }
                            else {
                              console.log("QRZ-2 Subscriber DATA SET");
                              this.qrzNotFound = false;
                              this.qrzSubscriber = res.QRZDatabase.Callsign;
                              //this.newlog.callsign = this.qrzSubscriber.call;
                              this.newlog.city = this.qrzSubscriber.addr2;
                              this.newlog.country = this.qrzSubscriber.country;
                              this.newlog.firstName = this.qrzSubscriber.fname;
                              this.newlog.fullName = this.qrzSubscriber.fname + " " + this.qrzSubscriber.name;
                              this.newlog.lastName = this.qrzSubscriber.name;
                              this.newlog.state = this.qrzSubscriber.state;
                              this.newlog.address = this.qrzSubscriber.addr1;
                              this.newlog.dxcc = this.qrzSubscriber.dxcc;
                              this.newlog.cqz = this.qrzSubscriber.cqzone;
                              this.newlog.ituz = this.qrzSubscriber.ituzone;
                              this.newlog.grid = this.qrzSubscriber.grid;
                              this.newlog.altCallsign = this.qrzSubscriber.aliases;
                              this.newlog.class = this.qrzSubscriber.class;
                              this.newlog.lat = this.qrzSubscriber.lat;
                              this.newlog.lon = this.qrzSubscriber.lon;
                              this.newlog.email = this.qrzSubscriber.email;
                              this.newlog.image = this.qrzSubscriber.image;
                              this.newlog.bio = this.qrzSubscriber.bio;
                              this.newlog.lookupSource = "QRZ";
                            }
                          }
                        }
                      });
                    }
                  }
                },
                error: (err) => {
                  console.error(err);
                }
              });
            }
            else {
              if (res.QRZDatabase.Session.SubExp == "non-subscriber") {
                console.log("QRZ-1 nonsub DATA SET");
                this.qrzSub = false;
                this.qrzNotFound = false;
                this.qrzNonSub = res.QRZDatabase.Callsign;
                //this.newlog.callsign = this.qrzNonSub.call;
                this.newlog.city = this.qrzNonSub.addr2;
                this.newlog.country = this.qrzNonSub.country;
                this.newlog.firstName = this.qrzNonSub.fname;
                this.newlog.fullName = this.qrzNonSub.fname + " " + this.qrzNonSub.name;
                this.newlog.lastName = this.qrzNonSub.name;
                this.newlog.state = this.qrzNonSub.state;
                this.newlog.lookupSource = "QRZ";
              }
              else {
                console.log("QRZ-1 Subscriber DATA SET");
                this.qrzNotFound = false;
                this.qrzSubscriber = res.QRZDatabase.Callsign;
                //this.newlog.callsign = this.qrzSubscriber.call;
                this.newlog.city = this.qrzSubscriber.addr2;
                this.newlog.country = this.qrzSubscriber.country;
                this.newlog.firstName = this.qrzSubscriber.fname;
                this.newlog.fullName = this.qrzSubscriber.fname + " " + this.qrzSubscriber.name;
                this.newlog.lastName = this.qrzSubscriber.name;
                this.newlog.state = this.qrzSubscriber.state;
                this.newlog.address = this.qrzSubscriber.addr1;
                this.newlog.dxcc = this.qrzSubscriber.dxcc;
                this.newlog.cqz = this.qrzSubscriber.cqzone;
                this.newlog.ituz = this.qrzSubscriber.ituzone;
                this.newlog.grid = this.qrzSubscriber.grid;
                this.newlog.altCallsign = this.qrzSubscriber.aliases;
                this.newlog.class = this.qrzSubscriber.class;
                this.newlog.lat = this.qrzSubscriber.lat;
                this.newlog.lon = this.qrzSubscriber.lon;
                this.newlog.email = this.qrzSubscriber.email;
                this.newlog.image = this.qrzSubscriber.image;
                this.newlog.bio = this.qrzSubscriber.bio;
                this.newlog.lookupSource = "QRZ";
              }
            }
          }
        }
      });
    }
  }
  searchHamlog(): void {
    this.hamsvc.getByCallUser(this.newlog.callsign, this.user.id).subscribe({
      next: (res) => {
        this.searchHamLogs = res;
        this.searchResCount = this.searchHamLogs.length;
        if (this.searchResCount === 0) {
          this.searchResMessage = `You haven't worked ${this.newlog.callsign}`;
        }
        if (this.searchResCount === 1) {
          this.searchResMessage = `You have worked ${this.newlog.callsign} ${this.searchResCount} Time`;
        }
        if (this.searchResCount > 1) {
          this.searchResMessage = `You have worked ${this.newlog.callsign} ${this.searchResCount} Times`;
        }
        this.searching = false;
        // runs when the call only exists in the user database
        if (this.searchHamLogs.length >= 1 && ((this.hamEn?.callsign != this.searchHamLogs[0].callsign) || this.hamEn === undefined || this.qrzNotFound)) {
          this.newlog.firstName = this.searchHamLogs[0].firstName;
          this.newlog.fullName = this.searchHamLogs[0].fullName;
          this.newlog.lastName = this.searchHamLogs[0].lastName;
          this.newlog.address = this.searchHamLogs[0].address;
          this.newlog.city = this.searchHamLogs[0].city;
          this.newlog.state = this.searchHamLogs[0].state;
          this.newlog.country = this.searchHamLogs[0].country;
          this.newlog.postalCode = this.searchHamLogs[0].postalCode;
          this.newlog.lookupSource = "Previous Log";
          console.debug("Ham did not exist in fcc database but did exist in your logs");
        }
        if(this.searchHamLogs.length >= 1){
          this.newlog.comments = this.searchHamLogs[0].comments;
        }
        // runs when the call does not exist in any database.
        if (this.searchHamLogs.length < 1 && (this.isaham === "notfound" || this.qrzNotFound)) {
          console.warn("Fcc NOT FOUND");
          this.warnMessage = "NOT FOUND!";
          this.newlog.lookupSource = "NOT FOUND";
          this.newlog.fullName = "";
          this.newlog.fccId = "";
          this.newlog.firstName = "";
          this.newlog.lastName = "";
          this.newlog.address = "";
          this.newlog.city = "";
          this.newlog.state = "";
          this.newlog.country = "";
          this.newlog.postalCode = "";
          this.newlog.comments = "";
        }
      },
      error: (err) => {
        console.error(err);
      }
    })
  }
  search(): void {
    if(this.newlog.callsign == ""){
      this.enterDisable = false;
      return;
    }
    this.warnMessageDesc = "";
    this.warnMessage = "";
    //TODO check for deuplicates in the table using ExecuteQuery if the mode is net-rt
    if (this.qrzEnable) {
      this.searchQRZ();
    }
    this.amasvc.getCallsign(this.newlog.callsign).subscribe({
      next: (res) => {
        this.hamEn = res;
        this.newlog.callsign = this.hamEn.callsign.toUpperCase();
        this.newlog.address = this.hamEn.address1;
        this.newlog.city = this.hamEn.city;
        this.newlog.state = this.hamEn.state;
        this.newlog.country = "United States"
        this.newlog.postalCode = this.hamEn.zip;
        this.newlog.fullName = this.hamEn.fullName;
        this.newlog.firstName = this.hamEn.first;
        this.newlog.lastName = this.hamEn.last;
        this.newlog.userId = this.user.id;
        this.newlog.fccId = this.hamEn.fccid.toString();
        let currentDate = this.parseDateTime(Date.now());
        this.newlog.dateAndTime = currentDate;
        this.newlog.lookupSource = "FCC DB";
        this.searchHamlog();
      },
      error: (err) => {
        let currentDate = Date.now();
        this.newlog.callsign = this.newlog.callsign.toUpperCase();
        this.newlog.userId = this.user.id;
        this.newlog.dateAndTime = this.pipe.transform(currentDate, 'yyyy-MM-ddTHH:mm:ss.sss');
        this.isaham = "notfound";
        this.searchHamlog();
        console.log(err);
      }
    });
    this.enterDisable = false;
    if(this.lookupPreviousLogs && !this.isNetRtMode){
      this.setSearchFilter();
    }
  }
  setSearchFilter() {
    this.grid?.filterByColumn("Callsign", "Contains", this.newlog.callsign, undefined, false)
  }
  clearAllSearchFilters() {
    this.grid?.clearFiltering();
  }

  recallNetsListModalFire(){
    this.hamsvc.netRtUniqueIdList(this.user.id).subscribe({
      next: (res) => {
        this.uniqueNetLogs = res;
        this.parsedNetLogs = [];
        this.uniqueNetLogs.forEach((log) => {
          if(log.netDate != null){
            log.netDate = this.parseDateTime(log.netDate);
            this.parsedNetLogs.push(log);
          }
        });

        this.parsedNetLogs.sort(function(a,b){return (b.netDate as Date).getTime() - (a.netDate as Date).getTime()});

        $('#recallNetsModal').modal('show');
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  startNetRt(isRecall: Boolean, netId = "", checkincount = 0) {
    this.showEmpty = true;
    if(!isRecall){
      this.isNetRtMode = true;
      this.lastNetRt = uuidv4();
      this.updateSelectedProfile("NET-RT");
    }
    else{
      this.checkincount = checkincount;
      this.isNetRtMode = true;
      this.lastNetRt = netId;
      this.updateSelectedProfile("NET-RT");
    }
  }

  endNetRt(){
    let offDate = Date.now();
    this.hamlogDataManager?.executeQuery(new Query(), (data) => {
      (data.actual.result as Hamlog[]).forEach((log) => {
  
       if(log.netLogs.offTime == null){
         log.timeOff = this.pipe.transform(offDate, 'yyyy-MM-ddTHH:mm:ss.sss');
       }
       else {
         log.timeOff = this.pipe.transform(log.netLogs.offTime, 'yyyy-MM-ddTHH:mm:ss.sss');
       }
       log.dateAndTime = this.pipe.transform(log.dateAndTime, 'yyyy-MM-ddTHH:mm:ss.sss');
       this.checkoutNetRt(log, true, true);
       this.hamsvc.change(log).subscribe({
         next:(res) => {
           
         },
         error:(err) => {
           console.log(err);
         }
       });
     });
    });
    this.newlog.qth = "";
    this.enteredfreq = "";
    this.updateSelectedProfile("NORMAL");
    this.isNetRtMode = false;
  }
  checkoutNetRt(hamlog: Hamlog, checkout: boolean = true, checkoutall: boolean = false){
    this.rowSelectCancel = true;
    let offDate = Date.now();
    hamlog.netLogs.checkedOut = checkout;
    hamlog.netLogs.offTime = this.pipe.transform(offDate, 'yyyy-MM-ddTHH:mm:ss.sss');
    this.netLogSvc.change(hamlog.netLogs).subscribe({
      next:(res) => {
        console.log("Net Log Updated");
        if(!checkoutall){
          this.grid?.refresh();
        }
      },
      error:(err) => {
        console.log(err);
      }
    });
  }
  emailLinkClicked() {
    this.rowSelectCancel = true;
  }
  oadCheckedChanged(hamlog: Hamlog) {
    this.rowSelectCancel = true;
    this.netLogSvc.change(hamlog.netLogs).subscribe({
      next:(res) => {
        console.log("Net Log Updated");
        this.grid?.refresh();
      },
      error:(err) => {
        console.log(err);
      }
    });
  }

  updateSelectedProfile(logMode: string) {
    this.logModeOptions[0] = logMode;
    this.logModeOptions[1] = this.lookupPreviousLogs == true ? "AUTOLOOKUPON" : "AUTOLOOKUPOFF";
    this.logModeOptions[2] = this.use24Hour == true ? "USE24HOUR" : "USE12HOUR";
    this.selectedUserProfile.logMode = "";
    this.selectedUserProfile.lastNetRt = this.lastNetRt;
    this.logModeOptions.forEach((option, index) => {
      if(index <= 2){
        this.selectedUserProfile.logMode += option + ",";
      }
    });
      this.userSetSvc.edit(this.selectedUserProfile.id, this.selectedUserProfile).subscribe({
        next: (res) => {
          console.log("User Profile Updated");
          this.logMode = logMode;
          if(logMode == "NORMAL") {
            this.isNetRtMode = false;
          }
          if(logMode == "NET-RT"){
            this.isNetRtMode = true;
          }
          this.initDataManager();
          this.showEmpty = false;
        },
        error: (err) => {
          console.error(err);
        }
      });
  }

  refresh(): void {
    this.searching = true;
    //let userId = this.route.snapshot.params["userId"];
    let newuserId = sessionStorage.getItem('userId');
    let userId = sessionStorage.getItem('userId') == null ? this.route.snapshot.params["userId"] : newuserId;
    this.urssvc.get(userId).subscribe({
      next: (res) => {
        this.user = res;

        this.newlog.mode = this.user.lastWorkedMode;
        this.newlog.power = +this.user.lastWorkedPower;
        this.userSetSvc.get(this.user.selectedSettingsProfileId).subscribe({
          next: (res) => {
            this.selectedUserProfile = res;
            this.showDxSpot = res.showDxSpot;
            this.showNetColumn = res.showNetColumn;
            this.numberOfCallsToShowPerPage = res.priviousCallsToShow;
            let modeSettings = res.logMode.split(",");
            this.logMode = modeSettings[0];
            this.lookupPreviousLogs = modeSettings[1] == "AUTOLOOKUPON" ? true : false;
            this.use24Hour = modeSettings[2] == "USE24HOUR" ? true : false;
            if(this.logMode === "NET-RT"){
              this.isNetRtMode = true;
              this.lastNetRt = this.selectedUserProfile.lastNetRt;
            }
            else {
              this.isNetRtMode = false;
            }
            //Removed
            //this.getHamLogs(userId);
            this.pageSettings = { pageSizes: ['5', '10', '12', '15', '20', '50'], pageSize: this.numberOfCallsToShowPerPage};

            if(!this.hamlogDataManager){
              this.initDataManager();
            }
            
            this.setClockTime();
          },
          error: (err) => {
            console.error(err);
          }
        });
      },
      error: (err) => {
        console.error(err);
      }
    });
  }
  parseDateTime(jsonDate) {
    let dateObject = new Date(jsonDate != null ? jsonDate : "");
    return dateObject //.toLocaleString('en-US');
  }
  clearSelectedRows() {
    (this.grid as GridComponent).clearSelection();
  }
  toast(title, content, colorClass = "") {
    $('#toast').removeClass('toastRed');
    $('#toast').removeClass('toastGreen');
    (this.toastMessageBox as ToastComponent).cssClass = colorClass;
    this.toastMessageTitle = title;
    this.toastMessageContent = content;
    (this.toastMessageBox as ToastComponent).show();
  }
  editSelectedRows() {
    this.selectedRecords = (this.grid as GridComponent).getSelectedRecords() as Hamlog[];
    if(this.selectedRecords.length < 1){
      this.toast("Nothing Selected!", "Select rows from the table to perform this action.", "toastRed");
    }
    else {
      this.selectedRecordsNewTon = this.parseDateTime(Date.now());
      this.selectedRecordsNewToff = this.parseDateTime(Date.now());
      $('#EditHamlogCollectionModal').modal('show');
      console.log(this.selectedRecords);

    }
  }

rowSelecting(args){ 
    if(this.rowSelectCancel){
      args.cancel = true;     //cancel the selection during opening context menu by right click 
      this.rowSelectCancel = false;
    }
  }

  updateSelectedRows() {
    this.selectedRecords.forEach((rec) => {
      if(this.selectedRecordsNewTonCB){
        rec.dateAndTime = this.pipe.transform(this.selectedRecordsNewTon as Date, 'yyyy-MM-ddTHH:mm:ss.sss');
      }
      else {
        rec.dateAndTime = this.pipe.transform(rec.dateAndTime as Date, 'yyyy-MM-ddTHH:mm:ss.sss');
      }
      if(this.selectedRecordsNewToffCB){
        rec.timeOff = this.pipe.transform(this.selectedRecordsNewToff as Date, 'yyyy-MM-ddTHH:mm:ss.sss');
      }
      else {
        rec.timeOff = this.pipe.transform(rec.timeOff as Date, 'yyyy-MM-ddTHH:mm:ss.sss');
      }
      if(this.selectedRecordsNewNetCB){
        rec.qth = this.selectedRecordsNewNet;
      }
      if(this.selectedRecordsNewCommentsCB){
        rec.comments = this.selectedRecordsNewComments;
      }
      if(this.selectedRecordsNewPowerCB){
        rec.power = this.selectedRecordsNewPower;
      }

    });
    this.hamsvc.changeList(this.selectedRecords).subscribe({
      next: res => {
        $('#EditHamlogCollectionModal').modal('hide');
        this.grid?.refresh();
        this.toast("Success!", "Selected rows have been successfully updated.", "toastGreen");

      },
      error: err => {
        $('#EditHamlogCollectionModal').modal('hide');
        console.warn(err);
      }
    });
  }
  deleteSelectedRows() {
    let ids = this.getSelectedRowIds();
    this.hamsvc.removeList(ids).subscribe({
      next: res => {
        this.user.recordCount -= ids.length;
        $('#EditHamlogCollectionModal').modal('hide');
        this.grid?.refresh();
        this.toast("Success!", "Selected rows have been successfully deleted.", "toastGreen");
      },
      error: err => {
        $('#EditHamlogCollectionModal').modal('hide');
        console.warn(err);
      }
    });
  }
  getSelectedRowIds() {
    let ids: string[] = [];
    this.selectedRecords.forEach((rec) => {
      ids.push(rec.id);
    });
    return ids;
  }
  netPresetSelected(args) {
    let netPreset = args.itemData as NetPreset;
    this.enteredfreq = netPreset.frequency;
    this.newlog.band = netPreset.band;
    this.newlog.mode = netPreset.mode;
  }

  initFilterTemplates(): void {

    // this.grid?.columnd.subscribe((args) => {
    //   console.log(args);
    //   localStorage.setItem('columnOrder', JSON.stringify(this.grid?.getColumns().map(col => col.field)));
    // });
    this.sortOptions = { columns: [{ field: 'DateAndTime', direction: 'Descending' }] };
    this.selectionOptions = { mode: 'Row', type: 'Multiple', enableSimpleMultiRowSelection: true, persistSelection: true, enableToggle: true };
    this.dateFormatOptions = {
      type: "date",
      format: "M/d/y"
    };

    this.templateOptionsDropDown = {
      create: () => {
        this.dropdown = document.createElement('select');
        this.dropdown.id = 'band';

        var option = document.createElement('option');
        option.value = 'All';
        option.innerText = 'All';
        this.dropdown.appendChild(option);

        this.allBands.forEach((item) => {
          let option = document.createElement('option');
          option.value = item.toString();
          option.innerText = item.toString();
          (this.dropdown as HTMLElement).appendChild(option);
        });
        return this.dropdown;
      },
      write: () => {
        const dropdownlist = new DropDownList({
          change: this.dropdownFunction.bind(this),
        });
        dropdownlist.appendTo(this.dropdown);
      },
    };
    this.templateOptionsDatePicker = {
      create: () => {
        this.dateElement = document.createElement('input');
        return this.dateElement;
      },
      write: (args: { column: { field: string | number | Date } }) => {
        const datePickerObj = new DatePicker({
          value: new Date(args.column.field),
          change: this.handleFilterChange.bind(this),
        });
        datePickerObj.appendTo(this.dateElement);
      },
    };
  }
  dropdownFunction(args: { value: string; item: { parentElement: { id: string } } }
  ) {
    if (args.value !== 'All') {
      (this.grid as GridComponent).filterByColumn(args.item.parentElement.id.replace('_options', ''), 'equal', Number(args.value));
    } else {
      (this.grid as GridComponent).removeFilteredColsByField(args.item.parentElement.id.replace('_options', ''));
    }
  }
  public handleFilterChange(args: { element: Element; value: string }) {
    let targetElement = parentsUntil(args.element, 'e-filtertext');
    let columnName: string = targetElement.id.replace('_filterBarcell', '');
    if (args.value) {
      (this.grid as GridComponent).filterByColumn(columnName, 'equal', args.value);
    } else {
      (this.grid as GridComponent).removeFilteredColsByField(columnName);
    }
  }

  gridRowDataBound(args: RowDataBoundEventArgs){
    if(args.row){
      let rowData = args.data as Hamlog;
      if(this.isNetRtMode){
        if(rowData.netLogs){
          if(rowData.netLogs.checkedOut){
            args.row.classList.add("Checked-out");
          }
          else {
            if(rowData.netLogs.oneAndDone){
              args.row.classList.add("OAD")
            }
            else{
              args.row.classList.add("Checked-in");
            }
          }   
        }
      }
    }
  }
  loadNewHamLogsTable() {
    // Set filterTemplate to an empty span to hide the filter bar for the template column
    ((this.grid as GridComponent).columns[0] as Column).filterTemplate = '<span></span>';
    ((this.grid as GridComponent).columns[2] as Column).filterTemplate = '<span></span>';
    ((this.grid as GridComponent).columns[3] as Column).filterTemplate = '<span></span>';
    ((this.grid as GridComponent).columns[8] as Column).filterTemplate = '<span></span>';
    ((this.grid as GridComponent).columns[9] as Column).filterTemplate = '<span></span>';
    ((this.grid as GridComponent).columns[13] as Column).filterTemplate = '<span></span>';
    ((this.grid as GridComponent).columns[14] as Column).filterTemplate = '<span></span>';
    ((this.grid as GridComponent).columns[15] as Column).filterTemplate = '<span></span>';
    ((this.grid as GridComponent).columns[17] as Column).filterTemplate = '<span></span>';
    ((this.grid as GridComponent).columns[18] as Column).filterTemplate = '<span></span>';
    ((this.grid as GridComponent).columns[19] as Column).filterTemplate = '<span></span>';
    ((this.grid as GridComponent).columns[20] as Column).filterTemplate = '<span></span>';
    ((this.grid as GridComponent).columns[21] as Column).filterTemplate = '<span></span>';
    ((this.grid as GridComponent).columns[22] as Column).filterTemplate = '<span></span>';
    ((this.grid as GridComponent).columns[23] as Column).filterTemplate = '<span></span>';
    ((this.grid as GridComponent).columns[24] as Column).filterTemplate = '<span></span>';
    this.timeFormatOptions = { type: "date", format: this.use24Hour ? "HH:mm:ss" : "h:mm:ss a" };    
  }
  hamLogTableOnChangeCompleted(event: PageEventArgs){
    if(event.requestType == 'paging'){
      if(this.grid?.pageSettings.pageSize != this.numberOfCallsToShowPerPage){
        let currentPageSize = this.grid?.pageSettings.pageSize;
        let notNullPageSize = currentPageSize == undefined ? 12 : currentPageSize;
        this.selectedUserProfile.priviousCallsToShow = notNullPageSize;
        this.userSetSvc.edit(this.selectedUserProfile.id, this.selectedUserProfile).subscribe({
          next: (res) => {
            console.log("Selected Profile Updated.");
          },
          error: (err) => {
            console.error(err);
          }
        });
      }
    }
  }
  hamlogInfoModalOpen(args: Hamlog) {
    this.rowSelectCancel = true;
    this.clearSelectedRows();
    this.grid?.refresh();
    //this.refresh();
    this.selectedHamlog = new Hamlog;
    this.selectedHamlog = args;
    this.timePickerScrollto = this.parseDateTime(Date.now());
  }
  setModalTimesNow(property: string) {
    switch(property){
      case "ton":
        this.selectedHamlog.dateAndTime = this.parseDateTime(Date.now());
        break;
      case "toff":
        this.selectedHamlog.timeOff = this.parseDateTime(Date.now());
        break;
      case "ton-multi":
        this.selectedRecordsNewTon = this.parseDateTime(Date.now());
        break
      case "toff-multi":
        this.selectedRecordsNewToff = this.parseDateTime(Date.now());
        break

    }
  }
  hasFlagInData(cont): boolean{
    let b = hasFlag(cont)
    return b
  }
  getCoutryCodes(name){
    let code = getCountryCode(name);
    return code;
  }
  ngOnDestroy() {
    clearInterval(this.intervalId);
    $('.grecaptcha-badge').css('visibility', 'visible');

  }
  setClockTime(): void {
    let newTimeFormat = this.use24Hour ? "HH:mm:ss" : "h:mm:ss a"
    this.timePickerFormat = newTimeFormat;
    this.intervalId = setInterval(() => {
      this.now = this.pipe.transform(new Date(), newTimeFormat);
      this.nowGMT = this.pipe.transform(new Date(), "HH:mm:ss", "GMT")
    }, 1);
  }
  initDataManager() {
    let passedId = "";
    if(!this.isNetRtMode){
      passedId = this.user.id;
    }
    else {
      passedId = this.lastNetRt;
    }

    this.hamlogDataManager = new DataManager({
      url: environment.CalllogAPIurl + "/api/hamlogs/datamanager/" + 
                                        passedId + 
                                        "/" + (this.isNetRtMode ? "NET-RT" : "NORMAL"),
      adaptor: new UrlAdaptor(),
      headers: [{ 'XApiKey': sessionStorage.getItem('XApiKey') }],
    });
    this.grid?.refresh();
  }
  ngOnInit(): void {
    $('.grecaptcha-badge').css('visibility', 'hidden');
    this.initFilterTemplates();
    this.initJqueryListener();
    this.filterSettings = {
      mode: 'Immediate',
      showFilterBarOperator: true,
    };
    this.tableHeight = "tableFixHeadShort";
    if (sessionStorage.getItem('userId') == null) {
      if (this.route.snapshot.params["userId"] != this.sys.userId) {
        this.router.navigateByUrl(`/login`);
      }
    }
    if (sessionStorage.getItem('qrze') == 'true') {
      this.qrzEnable = true;
    }
    this.refresh();
    
  }
}

