import { Component, viewChild, ViewChild } from '@angular/core';
import { NetPreset } from '../netpreset.class';
import { ToastComponent } from '@syncfusion/ej2-angular-notifications';
import { NetPresetService } from '../netpreset.service';
import { LoggerService } from 'src/app/user/logger.service';
import { DefaultGuid, DaysOfTheWeek, NumberDateToJson, Modes, Bands } from '../../globalVars';
import { MultiSelect } from '@syncfusion/ej2-angular-dropdowns';

@Component({
  selector: 'app-netpresets',
  templateUrl: './netpresets.component.html',
  styleUrl: './netpresets.component.css'
})
export class NetpresetsComponent {
  netPresets: NetPreset[] = [];
  @ViewChild('toastMessageBox') public toastMessageBox?: ToastComponent;
  public toastMessagePosition = { X: 'Right', Y: 'Top' };
  toastMessageContent: string = "";
  toastMessageTitle: string = "";
  daysOfTheWeek: string[] = DaysOfTheWeek;
  modes: string[] = Modes;
  bands: string[] = Bands;

  
  constructor(
    private _netPresetSvc: NetPresetService,
    private _loggerSvc: LoggerService
  ){}
  toast(title, content, colorClass = "") {
    $('#toast').removeClass('toastRed');
    $('#toast').removeClass('toastGreen');
    (this.toastMessageBox as ToastComponent).cssClass = colorClass;
    this.toastMessageTitle = title;
    this.toastMessageContent = content;
    (this.toastMessageBox as ToastComponent).show();
  }

  addNetPreset() {
    let newEmptyNetPreset = new NetPreset
    let newEmptyStringArray: string[] = [];
    newEmptyNetPreset.userId = this._loggerSvc.userId;
    newEmptyNetPreset.dow = newEmptyStringArray;
    this.netPresets.push(newEmptyNetPreset);
  }

  save() {
    this.netPresets.forEach((net) => {
      net.netTime = NumberDateToJson(net.netTime as Date);
      net.dow = (net.dow as string[]).join();
    });
    this._netPresetSvc.updateList(this.netPresets).subscribe({
      next: res => {
        console.log(res);
        this.refresh();
        this.toast("Success!", "Net Presets have been successfully updated.", "toastGreen");

      },
      error: err => {
        console.error(err);
      }
    });
  }
  delete(id){
    if(id == DefaultGuid) {
        this.netPresets.pop();
    }
    else {
      this._netPresetSvc.remove(id).subscribe({
        next: res => {
          this.refresh();
        },
        error: err => {
          console.error(err);
        }
      });
    }
  }

  refresh() {
    this._netPresetSvc.list(this._loggerSvc.userId).subscribe({
      next: res => {
        this.netPresets = res;
        this.netPresets.forEach((net) => {
          net.dow = (net.dow as string).split(",");
        });
      },
      error: err => {
        console.error(err);
      }
    });
  }

  ngOnInit() {
    this.refresh();
  }
}
