<app-menu></app-menu>
<header>
    <span id="pageTitle">{{pageTitle}}</span>
</header>

<table *ngIf="hamEn && hamEn.fccid != 0" class="table table-sm">
    <thead>
        <tr><th class="tablename">FCC Database</th></tr>
        <tr>
            <th>FCC ID</th>
            <th>Callsign</th>
            <th>Name</th>
            <th>Address</th>
            <th>City</th>
            <th>State</th>
            <th>Zip</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>{{hamEn.fccid}}</td>
            <td>{{hamEn.callsign}}</td>
            <td>{{hamEn.fullName}}</td>
            <td>{{hamEn.address1}}</td>
            <td>{{hamEn.city}}</td>
            <td>{{hamEn.state}}</td>
            <td>{{hamEn.zip}}</td>
        </tr>
    </tbody>
</table>
<table *ngIf="qrzNonSub && !qrzSub && qrzEnable"  class="table table-sm">
    <thead>
        <tr><th class="tablename">QRZ Database</th></tr>
        <tr>
            <th>Callsign</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Address2</th>
            <th>State</th>
            <th>Country</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>{{qrzNonSub.call}}</td>
            <td>{{qrzNonSub.fname}}</td>
            <td>{{qrzNonSub.name}}</td>
            <td>{{qrzNonSub.addr2}}</td>
            <td>{{qrzNonSub.state}}</td>
            <td>{{qrzNonSub.country}}</td>
        </tr>
    </tbody>
</table>
<table *ngIf="qrzSubscriber && qrzSub && qrzEnable"  class="table table-sm">
    <thead>
        <tr><th class="tablename">QRZ Database</th></tr>
        <tr>
            <th>Callsign</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Address 1</th>
            <th>Address 2</th>
            <th>State</th>
            <th>Country</th>
            <th>Grid</th>
            <th>Lat</th>
            <th>Lon</th>
            <th>Class</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>{{qrzSubscriber.call}}</td>
            <td>{{qrzSubscriber.fname}}</td>
            <td>{{qrzSubscriber.name}}</td>
            <td>{{qrzSubscriber.addr1}}</td>
            <td>{{qrzSubscriber.addr2}}</td>
            <td>{{qrzSubscriber.state}}</td>
            <td>{{qrzSubscriber.country}}</td>
            <td>{{qrzSubscriber.grid}}</td>
            <td>{{qrzSubscriber.lat}}</td>
            <td>{{qrzSubscriber.lon}}</td>
            <td>{{qrzSubscriber.class}}</td>
        </tr>
    </tbody>
</table>
<img style ="margin-left: 20px; max-width: 500px;" src="{{qrzSubscriber.image}}">
<table *ngIf="hamEn" class="table table-sm">
    <thead>
        <tr><th class="tablename">User Database</th></tr>
        <tr>
            <th>FCC ID</th>
            <th>Callsign</th>
            <th>Frequency</th>
            <th>Band</th>
            <th>Mode</th>
            <th>Power</th>
            <th>Date</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let h of hamlogs">
            <td>{{h.fccId}}</td>
            <td>{{h.callsign}}</td>
            <td>{{h.frequency}}</td>
            <td>{{h.band}}</td>
            <td>{{h.mode}}</td>
            <td>{{h.power}}</td>
            <td>{{h.dateAndTime | date:'long'}}</td>
        </tr>
    </tbody>
    </table>
