<div *ngIf="isValidated && validationCheckCompleted && !submissionCompleted" class="container h-100">
    <div class="row h-100">
      <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
        <div class="d-table-cell align-middle">
          <div class="text-center mt-4">
            <h1 class="h2">Reset Your Password Below!</h1>
            <p class="lead">
              Enter your new password below and confirm.
            </p>
          </div>
          <div class="card">
            <div class="card-body">
              <div *ngIf="!submissionCompleted" class="m-sm-4">
                <form [formGroup]="newPasswordForm" (ngSubmit)="validateCaptcha()">
                    <div class="form-group">
                    <label>Password</label>
                    <span *ngIf="password1.errors?.['required'] && password1.touched" class="warn-message"> - Field is Required!</span>
                    <input class="form-control form-control-lg" formControlName="password1" required type="password"
                      name="password" placeholder="Enter a new password" autocomplete="new-password">
                  </div>
                  <div class="form-group">
                      <label>Confirm Password</label>
                      <span *ngIf="password2.errors?.['required'] && password2.touched" class="warn-message"> - Field is Required!</span><br>
                    <input class="form-control form-control-lg" formControlName="password2" required type="password" name="password2" placeholder="Confirm your password" autocomplete="new-password-confirm">
                    <span *ngIf="newPasswordForm.errors?.['PasswordNoMatch'] && password1.touched && password2.touched" class="warn-message">Passwords Must Match!</span><br>
                    <ul *ngIf="password1.errors?.['pattern'] && password1.touched" class="warn-message">
                        Password Requirements: 
                        <li>Minimum Length is 8 Characters</li>
                        <li>Must Contain at Least 1 Uppercase Letter</li>
                        <li>Must Contain at Least 1 Lowercase Letter</li>
                        <li>Must Contain at Least 1 Number</li>
                        <li>Must Contain at Least 1 Special Character</li>
                    </ul>
                    <span class="warn-message">{{errorMessage}}</span><br>
                  </div>
                  <button type="submit" class="btn btn-primary" [disabled]="!newPasswordForm.valid">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isValidated && validationCheckCompleted && !submissionCompleted" class="container h-100">
    <div class="row h-100">
      <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
        <div class="d-table-cell align-middle">
          <div class="text-center mt-4">
            <h1 class="h2">Link Validation Failed!</h1>
            <p class="lead">
              We were unable to validate the authenticity of the link you used to arrive here. See the error message below.
            </p>
          </div>
          <div class="card">
            <div class="card-body text-center">
              <div class="m-sm-4">
                {{errorMessage}}
              </div>
              <div clas="m-sm-4">
                <a class="btn btn-primary" href="/login">Back to Login?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="submissionCompleted" class="container h-100">
    <div class="row h-100">
      <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
        <div class="d-table-cell align-middle">
          <div class="text-center mt-4">
            <h1 class="h2">Your password has successfully been updated!</h1>
            <p class="lead">
              Click below to navigate back to the login screen.
            </p>
          </div>
          <div class="card">
            <div class="card-body text-center">
              <div clas="m-sm-4">
                <a class="btn btn-primary" href="/login">LOGIN</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
