import { data } from "jquery";

export class SerialOptions {
  //  baudRate: 9600, dataBits: 8, parity: 'none', bufferSize: 256, flowControl: 'none'
    constructor(baudRate?: number, dataBits?: number, stopBits?: number, parity?: string, bufferSize?: number, flowControl?: string) {
        if(baudRate){
            this.baudRate = baudRate;
        }
        if(dataBits){
            this.dataBits = dataBits;
        }
        if(stopBits){
            this.stopBits = stopBits;
        }
        if(parity){
            this.parity = parity;
        }
        if(bufferSize) {
            this.bufferSize = bufferSize;
        }
        if(flowControl) {
            this.flowControl = flowControl;
        }
    }
    baudRate: number = 9600;
    dataBits: number = 8;
    stopBits: number = 2;
    parity: string = 'none';
    bufferSize: number = 256;
    flowControl: string = 'none'
}