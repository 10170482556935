<div id="component">
    <app-menu></app-menu>
    <app-time-out-modal></app-time-out-modal>
    <header>
        
        <span id="pageTitle">{{pageTitle}}</span>
        <a *ngIf="hamlog" routerLink="/logging/newlog/{{hamlog.userId}}" class="btn btn-primary btn-sm">Back</a>
        <button (click)="delete()" class="btn btn-danger btn-sm">Delete</button>
        <button *ngIf="areYouSure" (click)="deleteVerified()" class="btn btn-danger btn-sm">ARE YOU SURE?</button>
    </header>
    <main>
        <table class="table table-striped table-sm" *ngIf="hamlog">
            <tbody>
                <tr>
                    <td>Id</td>
                    <td><input [(ngModel)]="hamlog.id" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>Callsign</td>
                    <td><input [(ngModel)]="hamlog.callsign" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>FCC Id</td>
                    <td><input [(ngModel)]="hamlog.fccId" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>First Name</td>
                    <td><input [(ngModel)]="hamlog.firstName" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>Last Name</td>
                    <td><input [(ngModel)]="hamlog.lastName" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>Frequency</td>
                    <td><input [(ngModel)]="hamlog.frequency" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>Mode</td>
                    <td><input [(ngModel)]="hamlog.mode" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>Power</td>
                    <td><input [(ngModel)]="hamlog.power" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>RST Sent</td>
                    <td><input [(ngModel)]="hamlog.rsTsent" readonly disabled="true">
                        <div class="form-check"><label class="form-check-label" for="sent">QSL Sent?</label>
                            <input class="form-check-input" id="sent" type="checkbox" [(ngModel)]="hamlog.rstSentbool" disabled="true"> </div></td>
                </tr>
                <tr>
                    <td>RST rcv</td>
                    <td><input [(ngModel)]="hamlog.rsTrec" readonly disabled="true">
                        <div class="form-check"><label class="form-check-label" for="sent">QSL Received?</label>
                            <input class="form-check-input" id="sent" type="checkbox" [(ngModel)]="hamlog.rstRecbool" disabled="true"> </div></td>
                </tr>
                <tr>
                    <td>NET/Contest</td>
                    <td><input [(ngModel)]="hamlog.qth" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>T-On</td>
                    <td><input [ngModel]="hamlog.dateAndTime | date:'mediumTime'" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>T-Off</td>
                    <td><input [ngModel]="hamlog.timeOff | date: 'mediumTime'" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>Date</td>
                    <td><input [ngModel]="hamlog.dateAndTime | date: 'shortDate'" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>Address</td>
                    <td><input [(ngModel)]="hamlog.address" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>City</td>
                    <td><input [(ngModel)]="hamlog.city" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>State</td>
                    <td><input [(ngModel)]="hamlog.state" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>Zip</td>
                    <td><input [(ngModel)]="hamlog.postalCode" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>Alternate Callsign</td>
                    <td><input [(ngModel)]="hamlog.altCallsign" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>Call Modifier</td>
                    <td><input [(ngModel)]="hamlog.callModifier" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>Reciprical</td>
                    <td><input [(ngModel)]="hamlog.reciprical" readonly disabled="true"></td>
                </tr>
                <tr>
                    <td>Comments</td>
                    <td><textarea [(ngModel)]="hamlog.comments" readonly disabled="true" rows="4" cols="50"></textarea></td>
                </tr>
            </tbody>
        </table>

        
    </main>
</div>