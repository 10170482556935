import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NewsItem } from './newsitem.class';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NewsitemService {

  constructor(
    private http: HttpClient
  ) { }

  baseurl: string = environment.CalllogAPIurl + "/api/newsitems";

  list(): Observable<NewsItem[]> {
    return this.http.get(`${this.baseurl}`, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<NewsItem[]>;
  }
  updateList(newsItems: NewsItem[]): Observable<any> {
    return this.http.post(`${this.baseurl}/update/newsitems`, newsItems, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<any>;
  }
  remove(id: string): Observable<any> {
    return this.http.delete(`${this.baseurl}/${id}`, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<any>;
  }

}
