import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Spot } from './dxspot.class';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SpotService {

  baseurl: string = environment.CalllogAPIurl + "/api/spots";

  constructor(
    private http: HttpClient
  ) { }

  listSpots(quantity: number): Observable<Spot[]> {
    return this.http.get(`${this.baseurl}/list/${quantity}`, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<Spot[]>;
  }
  getSpot(id: number): Observable<Spot> {
    return this.http.get(`${this.baseurl}/${id}`, { headers: {['XApiKey']: `${sessionStorage.getItem('XApiKey')}`}}) as Observable<Spot>;
  }
}
