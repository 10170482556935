<app-menu></app-menu>

<div class="container h-100">
  <div class="row h-100">
    <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
      <div class="d-table-cell align-middle">

        <div class="text-center mt-4">
          <h1 class="h2">Welcome back</h1>
          <p class="lead">
            Sign in to your account to continue
          </p>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="m-sm-4">
              <div class="text-center">
                <img src="assets/images/avatar6.png" alt="" class="img-fluid rounded-circle" width="132" height="132">
              </div>
              <form #resetForm [formGroup]="loginForm" (ngSubmit)="validateCaptcha()">
                <div class="form-group">
                  <label>Username*</label>
                  <span *ngIf="username.errors?.['required'] && username.touched" class="warn-message"> - Field is Required!</span><br>
                  <input class="form-control form-control-lg" formControlName="username" required type="username"
                    name="username" placeholder="Enter your Username" autocomplete="username">
                </div>
                <div class="form-group">
                  <label>Password*</label>
                  <span *ngIf="password.errors?.['required'] && password.touched" class="warn-message"> - Field is Required!</span><br>
                  <input class="form-control form-control-lg" formControlName="password" type="password" name="password"
                    placeholder="Enter your password" autocomplete="password">
                  <span class="warn-message">{{message}}</span><br>
                    <small>
                    <a routerLink="/request/reset">Forgot password?</a>
                  </small>
                </div>
                <div>
                  <div class="custom-control custom-checkbox align-items-center">
                    <input type="checkbox" class="custom-control-input" value="remember-me" name="remember-me"
                      checked="">
                    <label class="custom-control-label text-small">Remember me next time</label>
                  </div>
                </div>
                <div class="text-center mt-3">
                  <!-- <a href="index.html" class="btn btn-lg btn-primary">Sign in</a> -->
                  <button type="submit" class="btn btn-lg btn-primary">Sign in</button><span
                    style="margin-left: 20px;"><a routerLink="/request/signup">Sign Up</a></span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>