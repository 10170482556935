<nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
    <a style="margin-left: 10px;" class="navbar-brand" href="#">W8ZJT</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" routerLink="">Home</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/user/list" *ngIf="isAdmin && isLoggedIn">Users</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" role="button" (click)="goToLogging()">Logging</a>
        </li>

        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                WX
              </a>
              <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdown">
                <li>
                    <a class="dropdown-item" routerLink="/weather">Weather</a>
                </li>
              </ul>
        </li>

        <li class="nav-item dropdown" *ngIf="isLoggedIn">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Settings
          </a>
          <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdown">
            <li>
                <a class="dropdown-item" routerLink="/settings/list/{{userId}}">User Settings</a>
            </li>
            <div class="dropdown-divider"></div>
            <li *ngIf="isSubAdmin">
                <a class="dropdown-item" routerLink="/settings/newsitems/edit">News Items</a>
            </li>
            <li>
                <a class="dropdown-item" routerLink="/settings/netpresets/edit">Net Presets</a>
            </li>
            <!-- <li>
                <a class="dropdown-item" routerLink="/spots/config/{{userId}}">DX Cluster</a>
            </li> -->
            <!-- <a class="dropdown-item" href="#">Something else here</a> -->
          </ul>
        </li>
        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              About
            </a>
            <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdown">
                <div class="dropdown-divider"></div>
              <li>
                  <a class="dropdown-item" routerLink="/about">About</a>
              </li>
              <div class="dropdown-divider"></div>
              <li>
                  <a class="dropdown-item" routerLink="/documentation">Documentation</a>
              </li>
              <li>
                  <a class="dropdown-item" routerLink="/changes">Change Log</a>
              </li>
              <!-- <a class="dropdown-item" href="#">Something else here</a> -->
            </ul>
          </li>
        <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Other Services
            </a>
            <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdown">
                <li>
                    <a class="dropdown-item" href="https://cloud.w8zjt.net">W8ZJT Cloud</a>
                </li>
                <li>
                    <a class="dropdown-item" href="https://mail.w8zjt.net" *ngIf="isAdmin && isLoggedIn">Email Client</a>
                </li>
                <div class="dropdown-divider"></div>
                <li>
                    <a class="dropdown-item" routerLink="/spots/config/{{userId}}">DX Cluster</a>
                </li>
            </ul>
        </li> -->
        <li class="nav-item">
            <a style="margin-top: 5px; margin-right: 5px;" role="button" (click)="search()" class="btn btn-primary btn-sm">Search</a>        
        </li>
        <li class="nav-item">
            <input style="margin-top: 5px;" aria-label="Search" [(ngModel)]="searchCallsign" type="search" placeholder="Callsign:">
        </li>
    </ul>
    <ul class="navbar-nav ms-auto" style="float: right;">
        <li>
            <a role="button" class="nav-link" (click)="logout()"routerLink="logout">{{username.toUpperCase()}}</a>
        </li>
    </ul>
</div>
  </nav>
