import { Component } from '@angular/core';
import { User } from '../user.class';
import { UserService } from '../user.service';
import {  Router } from '@angular/router';
import { encrypt } from 'node_modules/dsi-encrypt-password/index.js';
import { DatePipe } from '@angular/common';
import { UserSettingService } from '../user-setting.service';
import { UserSetting } from '../userSetting.class';


@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent {
  user: User = new User();
  pageTitle = "New User";
  pipe = new DatePipe('en-US');
  defaultProfileId: string = "";
  newUser!: User;

  constructor(
    private usrsvc: UserService,
    private router: Router,
    private usrSetsvc: UserSettingService
    
  ){}

  save():void {
    this.user.password = encrypt(this.user.password);
    console.log(this.user);
    this.user.callsign = this.user.callsign.toUpperCase();
    this.user.isAdmin = (this.user.isAdmin)? true : false;
    this.user.qrzUserName = this.usrsvc.encryptData(this.user.qrzUserName);
    this.user.qrzPassword = this.usrsvc.encryptData(this.user.qrzPassword);
    this.usrsvc.create(this.user).subscribe({
      next: (res) => {
        console.debug("User Created!");
        this.newUser = res;
        let newProfile = new UserSetting;
        newProfile.userId = res.id;
        newProfile.profileName = "Default Profile";
        this.usrSetsvc.create(newProfile).subscribe({
          next: (res) => {
            console.log("Default Profile Created");
            this.newUser.selectedSettingsProfileId = res.id;
            this.usrsvc.change(this.newUser).subscribe({
              next: (res) => {
                console.log("User Default Profile Selected");
              },
              error: (err) => {
                console.error(err);
              }
            });
          },
          error: (err) => {
            console.error(err);
          }
        });
        this.router.navigateByUrl("/user/list");
      },
      error: (err) => {
        console.error(err);
      }
    })
    
  }

}
