import { DefaultGuid } from "../globalVars";
export class NetPreset {
    id: string = DefaultGuid;
    userId: string = DefaultGuid;
    name: string = "";
    description: string = "";
    frequency: string = "";
    band: string = "";
    mode: string = "";
    dow: string  | string[] = "";
    dateCreated: string | Date | null = null;
    dateModified: string | Date | null = null;
    netTime: string | Date | null = null;

}