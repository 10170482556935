import { DefaultGuid } from "../settings/globalVars";
export class UserSetting {
    id: string = DefaultGuid;
    userId: string  = DefaultGuid;
    lastNetRt: string = DefaultGuid;
    profileName: string = "";
    userNotes: string = "";
    showNetColumn: boolean = true;
    showAddressColumn: boolean = true;
    showDxSpot: boolean = true;
    filterDxSpots: string = "";
    spotsSortBy: string = "";
    spotsRefreshRate: number = 1000;
    style: string = "";
    logMode: string = "NORMAL,AUTOLOOKUPON,USE12HOUR";
    netLogMode: boolean = false;
    numberOfCallsToShow: number = 50;
    priviousCallsToShow: number = 12;
    dateCreated: string | null = null;
    dateModified: string | null = null;
    defaultMode: string = "LSB";
    defaultPower: number = 100;
    radioSelected: string = "";
    rigControlEnabled: boolean = false;
    serialAutoStart: boolean = false;
    baudRate: number = 0;
    pollingRateMs: number = 500;
    dataBits: number = 8;
    stopBits: number = 2;
    bufferSize: number = 256;
    parity: string = "none";
    flowControl: string = "none";


}
