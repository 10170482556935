import { Netlog } from "./netlog.class";
import { DefaultGuid } from "../settings/globalVars";

export class Hamlog {
    id: string = DefaultGuid;
    userId: string = DefaultGuid;
    // user!: User;
    fccId: string = "";
    callsign: string = "";
    altCallsign: string = "";
    callModifier: string = "";
    reciprical: string = "";
    frequency: string = "";
    mode: string = "";
    rsTsent: string = "59";
    rsTrec: string = "59";
    qslsent: string = "N";
    qslrec: string = "N";
    lotw_QSL_SENT = "N";
    band: string = "0";
    power: number = 0;
    firstName: string = "";
    lastName: string = "";
    fullName: string = "";
    country: string = "United States";
    county: string = "";
    cont: string = "";
    dxcc: string = "";
    cqz: string = "";
    ituz: string = "";
    pfx: string = ""
    class: string = "";
    lat: string = "";
    lon: string = "";
    email: string = "";
    image: string = "";
    bio: string = "";
    grid: string = ""
    city: string = "";
    state: string = "";
    postalCode: string = "";
    address: string = "";
    comments: string = "";
    qth: string = "";
    logCount: number = 0;
    dateAndTime: string | Date | null = null;
    timeOff: string | Date | null = null;
    dateCreated: string | null = null;
    dateModified: string | null = null;
    rstSentbool: boolean = false;
    rstRecbool: boolean = false;
    lookupSource: string = "";
    netLogs!: Netlog;


}