import { Component, ViewChild } from '@angular/core';
import { UserService } from 'src/app/user/user.service';
import { User } from 'src/app/user/user.class';
import { LoggerService } from 'src/app/user/logger.service';
import { Router } from '@angular/router';
import { HamlogService } from 'src/app/hamlog/hamlog.service';
import { Observable, Subscription } from 'rxjs';
import { UserSetting } from 'src/app/user/userSetting.class';
import { UserSettingService } from 'src/app/user/user-setting.service';
import { SerialInterfaceComponent } from 'src/app/rig_interface/serial-interface/serial-interface.component';



@Component({
  selector: 'app-settings-list',
  templateUrl: './settings-list.component.html',
  styleUrls: ['./settings-list.component.css']
})
export class SettingsListComponent {


  user!: User;

  jsonStyleString: string = "";
  styleColor: string = "";
  userId: string = "";
  currentFile?: File;
  progress = 0;
  message: string = "Edit User Settings";
  error: boolean = false;
  fileName = 'Select File';
  fileInfos?: Observable<any>;
  intervalId: any;
  newLogRowCount: number = 0;
  newLogDuplicates: number = 0;
  uploadSuccess: boolean = false;
  currentlyUploading: boolean = false;
  uploadSub!: Subscription;
  newProfile: UserSetting = new UserSetting;
  currentSelectedProfile!: UserSetting;
  selectProfileId: string = "";
  availableProfiles!: UserSetting[];
  editableProfile: boolean = false;
  modalDeleteOrCancel: string = "Delete";
  areYouSureModalDelete: boolean = false;
  originalPnameEdit: string = "";
  qrzEnabled: boolean = false;
  numberOfCallsToShow: number[] = [5, 10, 12, 15, 20, 50];
  logModeOptions: string[] = [];
  newLogModeOption: string = "NORMAL";
  logModeBaseOptions: string[] = ["NORMAL", "NET-RT"];
  automaticLookupPreviousLogs: boolean = false;
  newAutomaticLookupPreviousLogs: boolean = true;
  use24Hour: boolean = false;
  newUse24Hour: boolean = false;
  public fields: Object = { text: 'profileName', value: 'id' };
  @ViewChild('rigcontrol') public rigControl?: SerialInterfaceComponent;
  

  constructor(
    private usrsvc: UserService,
    private sys: LoggerService,
    private router: Router,
    private hamsvc: HamlogService,
    private userSetSvc: UserSettingService
    
    
  ){}

  openSessionTimeoutModal() {
    ($('#TimeOutModal') as any).modal('show');
  }
  isProfileNameValid(name: string){
    let isValid = true;
    if(name.toLowerCase() == "default profile" || name == ""){
      isValid = false;
    }
    else{
      this.availableProfiles.forEach((profile) => {
        if(name.toLowerCase() == profile.profileName.toLowerCase()){
          isValid = false;
        }
      });
    }
    return isValid;
  }
  resetModalOptions(){
    this.modalDeleteOrCancel = "Delete";
    this.areYouSureModalDelete = false;
  }
  deleteProfileFromModal(): void {
    this.areYouSureModalDelete = !this.areYouSureModalDelete;
    this.modalDeleteOrCancel = !this.areYouSureModalDelete ? "Delete" : "Cancel?";
  }
  deleteFromModalVerified(): void {
    this.userSetSvc.remove(this.selectProfileId).subscribe({
      next: (res) => {
        console.debug("Deleted!");
        this.deleteProfileFromModal();
        this.refresh();
        this.availableProfiles.forEach((profile) => {
          if(profile.profileName.toLowerCase() == "default profile"){
            this.user.selectedSettingsProfileId = profile.id;
          }
        });
        this.usrsvc.change(this.user).subscribe({
          next: (res) => {
            console.log("Selected User Profile Changed To Default Profile");
            this.message = "Selected Profile Default";
            this.error = false;
            this.refresh();
          },
          error: (err) => {
            console.error(err);
          }
        });
      },
      error: (err) => {
        console.error(err);
      }
    })
  }

  editSaveSelectedProfile(skipValidate = false) {
    let isValid = true;
    this.logModeOptions[1] = this.automaticLookupPreviousLogs == true ? "AUTOLOOKUPON" : "AUTOLOOKUPOFF";
    this.logModeOptions[2] = this.use24Hour == true ? "USE24HOUR" : "USE12HOUR";
    this.currentSelectedProfile.logMode = "";
    this.logModeOptions.forEach((option, index) => {
      if(index <= 2){
        this.currentSelectedProfile.logMode += option + ",";
      }
    });
    if(!skipValidate){
      if(this.currentSelectedProfile.profileName.toLowerCase() != this.originalPnameEdit.toLowerCase()){
        isValid = this.isProfileNameValid(this.currentSelectedProfile.profileName);
      }
    }
    if(isValid){
      this.userSetSvc.edit(this.currentSelectedProfile.id, this.currentSelectedProfile).subscribe({
        next: (res) => {
          console.log("User Profile Updated");
          this.refresh();
        },
        error: (err) => {
          console.error(err);
        }
      });
    }
    else{
      this.message = "Cannot Save Changes, Profile Name Already Exists."
      this.error = true;
      console.error("Profile Name Exists");
      this.refresh();
    }
  }

  // on edit modal open
  setOriginalPname(){
    this.originalPnameEdit = this.currentSelectedProfile.profileName;
  }

  changeSelectedProfile(event: any){
    if(event.isInteracted){
      this.user.selectedSettingsProfileId = this.selectProfileId;
      this.usrsvc.change(this.user).subscribe({
        next: (res) => {
          console.log("Selected User Profile Updated!");
          this.message = "Selected Profile Updated";
          this.error = false;
          this.refresh();
        },
        error: (err) => {
          console.error(err);
        }
      });
    }
  }
  changeUserQRZsettings(){
    this.user.qrzLookupEnabled = this.qrzEnabled;
    this.user.qrzUserName = this.usrsvc.encryptData(this.user.qrzUserName);
    this.user.qrzPassword = this.usrsvc.encryptData(this.user.qrzPassword);
    sessionStorage.setItem('qrze', this.qrzEnabled ? "true" : "false");
    sessionStorage.setItem('qrzSessionKey', "");
    sessionStorage.setItem('qrzu', this.user.qrzUserName);
    sessionStorage.setItem('qrzp', this.user.qrzPassword);
    this.usrsvc.change(this.user).subscribe({
      next: (res) => {
        console.log("QRZ Settings successfully updated");
        this.message = "QRZ Settings Updated";
        this.error = false;
        this.refresh();
      },
      error: (err) => {
        console.error(err);
      }
    });
  
  }
  createNewUserProfile(){
    var isValid = this.isProfileNameValid(this.newProfile.profileName);
    if(isValid){
      this.newProfile.logMode = "";
      let lookupPreviousOption = this.newAutomaticLookupPreviousLogs ? "AUTOLOOKUPON" : "AUTOLOOKUPOFF";
      let use24HourOption = this.newUse24Hour ? "USE24HOUR" : "USE12HOUR";
      this.newProfile.logMode += this.newLogModeOption + "," + lookupPreviousOption + "," + use24HourOption;
      this.newProfile.userId = this.user.id;
      this.userSetSvc.create(this.newProfile).subscribe({
        next: (res) => {
          console.log("New Setting Profile Created");
          this.refresh();
          this.newProfile = new UserSetting;
        },
        error: (err) => {
          console.error(err);
        }
      });
    }
    else{
      this.message = "Error Creating Profile, Profile Name Already Exists."
      this.error = true;
      console.error("Profile Name Exists");
      this.refresh();
      this.newProfile = new UserSetting;
    }
  }
  fileSelection(event: any):void {
    this.progress = 0;
    this.message = "";

    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      this.currentFile = file;
      this.fileName = this.currentFile.name;
    } else {
      this.fileName = 'Select File';
    }
  }
  startProgressReport() {
    this.intervalId = setInterval(() => {
      this.usrsvc.getUploadProgress(this.user.id).subscribe({
        next: (res) => {
          this.progress = res;
          if(this.progress == 100){
            this.stopProgressReport();
            this.currentlyUploading = true;
            this.uploadSuccess = true;
          }
        },
        error: (err) => {
          console.error(err);
          this.stopProgressReport();
          this.currentlyUploading = false;
          this.uploadSuccess = false;
        }
      })
    }, 1000);
  }
  stopProgressReport() {
    clearInterval(this.intervalId);
  }
  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.stopProgressReport();
    this.currentlyUploading = false;
    this.uploadSuccess = false;
    this.progress = 0;
  }
  uploadFileAdi(): void{
    if (this.currentFile) {
      this.startProgressReport();
      this.currentlyUploading = true;
      this.uploadSub = this.hamsvc.uploadAdi(this.user.id, this.currentFile).subscribe({
        next: (res) => {
          console.log(res);
          this.uploadSuccess = true;
          this.currentlyUploading = false;
          this.newLogRowCount = res.rowCount;
          this.newLogDuplicates = res.duplicates;
          this.uploadSuccess = res.importResult;

        },
        error: (err) => {
          console.error(err);
          this.currentlyUploading = false;
          this.uploadSuccess = false;
          this.stopProgressReport();
          
        }
      });
    }
  }

  save(): void {
    this.usrsvc.change(this.user).subscribe({
      next: (res) => {
        console.debug("User Settings Updated");
        // this.router.navigateByUrl(`/logging/newlog/${this.userId}`);
        
        
      },
      error: (err) => {
        console.error(err);
      }
    })
  }
  refresh(): void {
    this.userId = this.sys.userId;
    this.usrsvc.get(this.userId).subscribe({
      next: (res) => {
        this.user = res;
        this.qrzEnabled = this.user.qrzLookupEnabled;
        this.availableProfiles = res.userSettings;
        this.user.qrzUserName = this.usrsvc.decryptData(this.user.qrzUserName);
        this.user.qrzPassword = this.usrsvc.decryptData(this.user.qrzPassword);
        this.userSetSvc.get(this.user.selectedSettingsProfileId).subscribe({
          next: (res) => {
            this.currentSelectedProfile = res;
            this.selectProfileId = res.id;
            if(res.profileName == "Default Profile"){
              this.editableProfile = false;
            }
            else{
              this.editableProfile = true;
            }
            this.logModeOptions = res.logMode.split(",");
            this.automaticLookupPreviousLogs = this.logModeOptions[1] == "AUTOLOOKUPON" ? true : false;
            this.use24Hour = this.logModeOptions[2] == "USE24HOUR" ? true : false;
          },
          error: (err) => {
            console.error(err);
          }
        });
      },
      error: (err) => {
        console.error(err);
      }
    });
  }
  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  ngOnInit(): void {

    this.refresh();
  }
}
