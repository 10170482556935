import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NgxSerial } from './radio-command-data/utilities/serial/ngx-serial';
import { radioSelector } from './radio-command-data/utilities/radio-selector';
import { UserSetting } from 'src/app/user/userSetting.class';
import { UserSettingService } from 'src/app/user/user-setting.service';
import { parseToHz } from './radio-command-data/utilities/frequencyHelper';
import { User } from 'src/app/user/user.class';
import { SerialOptions } from './radio-command-data/utilities/serial/serialOptions';
import { supportedRadios, availableCatagories, serialDefualts, possibleBaudRates, SupportedRadio } from './radio-command-data/radios/program-defaults';

@Component({
  selector: 'app-serial-interface',
  templateUrl: './serial-interface.component.html',
  styleUrl: './serial-interface.component.css'
})
export class SerialInterfaceComponent {
  // -------------> Radio Options List
  selectedCat: string = "";
  selectedRadio: string = "";
  availableCatagories = availableCatagories;
  availableRadios: SupportedRadio[] = [];
  // -------------> Serial Config Props

  currentSerialOptions: SerialOptions = new SerialOptions;
  autoStartSerial: boolean = false;
  possibleBaudRates = possibleBaudRates;

  // -------------> Radio Control Props
  intervalId: any;
  selector: radioSelector = new radioSelector;
  radio: any;
  serial!: NgxSerial;
  port: any;
  newfreq: string = "";
  inputBoxFreqRequest: string = "";
  inputBoxModeRequest: string = "";
  selectChangeVfo: string = "set_VFO_A";
  userSettings!: UserSetting;

  @Input() radioCurrentFrequency?: string;
  @Input() radioCurrentBand?: string;
  @Input() radioCurrentMode?: string;
  @Input({ required: true }) profileId!: string;

  @Output() radioCurrentFrequencyChange = new EventEmitter();
  @Output() radioCurrentBandChange = new EventEmitter();
  @Output() radioCurrentModeChange = new EventEmitter();

  constructor(
    private userSetSvc: UserSettingService
  ) { }
  @HostListener('document:keyup', ['$event'])
  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    let inputKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace"];
    if ($('#RigControlModal').hasClass('show')) {
      if (inputKeys.includes(event.key)) {
        if (event.key == "Backspace") {
          this.KeyPadWrite(-1);
        }
        else {
          this.KeyPadWrite(event.key);
        }
      }
    }
  }

  frequencyOnChange() {
    this.radioCurrentFrequencyChange.emit(this.radioCurrentFrequency);
  }
  bandOnChange() {
    this.radioCurrentBandChange.emit(this.radioCurrentBand);
  }
  modeOnChange() {
    this.radioCurrentModeChange.emit(this.radioCurrentMode);
  }

  changeFrequency() {
    console.debug(this.newfreq, this.selectChangeVfo);
    this.radio.changeFrequency(this.newfreq, this.selectChangeVfo);
  }
  changeMode() {
    console.debug(this.inputBoxModeRequest);
    this.radio.changeMode(this.inputBoxModeRequest);
  }

  connect() {
    this.port = this.radio.connect();
    this.startReadLoop();
  }

  getPortInfo() {
    console.log(this.port.getInfo());
  }

  readFreq() {
    this.radio.read();
    this.radioCurrentFrequency = this.radio.currentFrequency;
    this.frequencyOnChange();
    this.radioCurrentBand = this.radio.currentBand;
    this.bandOnChange();
    if (this.radioCurrentMode != this.radio.currentMode) {
      this.inputBoxModeRequest = this.radio.currentMode;
      this.radioCurrentMode = this.radio.currentMode;
      this.modeOnChange();
    }
  }

  startReadLoop() {
    this.intervalId = setInterval(() => {
      this.readFreq();
    }, 500);
  }
  stopReadLoop() {
    clearInterval(this.intervalId);
  }

  requestFrequencyChange() {
    this.inputBoxFreqRequest = ($('#ShortCode').val() as string);
    this.newfreq = parseToHz(this.inputBoxFreqRequest);
    this.changeFrequency();
  }


  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  changeRig() {
    this.availableRadios = supportedRadios[this.selectedCat].supportedRadios;

  }
  genRadioInstance() {
    this.radio = this.selector.selectRadio(this.selectedCat, this.selectedRadio, this.currentSerialOptions);
    if (this.radio != null) {
      this.radio.initSerialDataHandler();
    }
  }
  changeRadio() {
    this.closeConnection();
    this.currentSerialOptions = serialDefualts[this.selectedCat][this.selectedRadio];
    this.saveRigSettingsToProfile();
  }
  closeConnection() {
    this.stopReadLoop();
    this.port = null;
    this.radio.close();
  }
  refresh() {
    this.getProfile();

  }
  // hiddenConnectButtonOnClick() {
  //   this.connect();
  //   if (this.port) {
  //     this.startReadLoop();
  //   }
  // }

  openRigControlModal() {
    $('#RigControlModal').modal('show');
  }
  openSerialSettingsModal() {
    $('#SerialSettingsModal').modal('show');
  }
  getProfile() {
    this.userSetSvc.get(this.profileId).subscribe({
      next: (res) => {
        this.userSettings = res;
        if (this.userSettings.radioSelected != "" && this.userSettings.radioSelected != null) {
          let radioAndCat = this.userSettings.radioSelected.split(",");
          this.selectedCat = radioAndCat[0];
          this.selectedRadio = radioAndCat[1];
          this.availableRadios = supportedRadios[this.selectedCat].supportedRadios;
          this.autoStartSerial = this.userSettings.serialAutoStart;
        }
        if (this.userSettings.baudRate != null) {
          this.currentSerialOptions = new SerialOptions(
            this.userSettings.baudRate,
            this.userSettings.dataBits,
            this.userSettings.stopBits,
            this.userSettings.parity,
            this.userSettings.bufferSize,
            this.userSettings.flowControl
          );
        }
        else {
          if (this.userSettings.radioSelected != "" && this.userSettings.radioSelected != null) {
            this.currentSerialOptions = serialDefualts[this.selectedCat][this.selectedRadio];
          }
        }
        this.genRadioInstance();
        //$('#runStartBtn').trigger('click');
      },
      error: (err) => {
        console.error(err);
      }
    });
  }
  saveRigSettingsToProfile() {
    this.userSettings.baudRate = this.currentSerialOptions.baudRate;
    this.userSettings.dataBits = this.currentSerialOptions.dataBits;
    this.userSettings.stopBits = this.currentSerialOptions.stopBits;
    this.userSettings.parity = this.currentSerialOptions.parity;
    this.userSettings.bufferSize = this.currentSerialOptions.bufferSize;
    this.userSettings.flowControl = this.currentSerialOptions.flowControl;
    this.userSettings.serialAutoStart = this.autoStartSerial;
    this.userSettings.radioSelected = this.selectedCat + "," + this.selectedRadio;
    this.userSetSvc.edit(this.userSettings.id, this.userSettings).subscribe({
      next: (res) => {
        console.log("User Profile Updated");
        this.refresh();
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  KeyPadWrite(add) {
    if (add == "-1") {
      $('#ShortCode').val(($('#ShortCode').val() as string).slice(0, -1));
      return;
    }
    if (($('#ShortCode').val() as string).includes(".") && add == ".") {
      return;
    }
    if (!($('#ShortCode').val() as string).includes(".") && ($('#ShortCode').val() as string).length >= 2 && add != ".") {
      return;
    }
    if (($('#ShortCode').val() as string).includes(".")) {
      let splitDot = ($('#ShortCode').val() as string).split(".");
      if (splitDot[1].length >= 6) {
        return;
      }
    }
    $('#ShortCode').val($('#ShortCode').val() + add);
  }

  ngOnInit() {
    this.refresh();
  }
}
