import { Component } from '@angular/core';
import { encrypt } from 'node_modules/dsi-encrypt-password/index.js';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NewUserForm, NewUserValidate } from '../requests.class';
import { UserService } from '../user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-newuser',
  templateUrl: './newuser.component.html',
  styleUrl: './newuser.component.css'
})
export class NewuserComponent {

  errorMessage: string = "";
  uid: string = "";
  userId: string = "";
  isValidated: boolean = false;
  submissionCompleted: boolean = false;
  buttonDisable: boolean = false;

  validateUserForm!: FormGroup;
  username: FormControl = new FormControl('', Validators.required);
  password: FormControl = new FormControl('', Validators.required);

  newUserForm!: FormGroup;
  firstname: FormControl = new FormControl('', Validators.required);
  lastname: FormControl = new FormControl('', Validators.required);
  address: FormControl = new FormControl('', [Validators.required]);
  address2: FormControl = new FormControl('');
  city: FormControl = new FormControl('', [Validators.required]);
  state: FormControl = new FormControl('', [Validators.required]);
  postalCode: FormControl = new FormControl('', [Validators.required]);
  country: FormControl = new FormControl('', [Validators.required]);




 constructor(
  private recaptchaV3Service: ReCaptchaV3Service,
  private _usrsvc: UserService,
  private route: ActivatedRoute,
  private router: Router,
  private formbuilder: FormBuilder,
 ) {
  this.validateUserForm = this.formbuilder.group({
    username: this.username,
    password: this.password
  });
  this.newUserForm = this.formbuilder.group({
    firstname: this.firstname,
    lastname: this.lastname,
    address: this.address,
    address2: this.address2,
    city: this.city,
    state: this.state,
    postalCode: this.postalCode,
    country: this.country,
  });
 }


  validateCaptcha() {
    this.buttonDisable = true;
    this.recaptchaV3Service.execute('importantAction')
    .subscribe({
      next: (res) => {
        if(!this.isValidated){
          this.submitValidation(res);
        }
        else {
          this.submitData(res);
        }
      },
      error: (err) => {
        console.error(err);
        this.errorMessage = "Recaptcha could not be validated.";
      }
    });
  }
  submitData(token: string) {
    let newUserData = new NewUserForm(
      this.uid,
      this.userId,
      token,
      this.newUserForm.value.firstname,
      this.newUserForm.value.lastname,
      this.newUserForm.value.address,
      this.newUserForm.value.address2,
      this.newUserForm.value.city,
      this.newUserForm.value.state,
      this.newUserForm.value.postalCode,
      this.newUserForm.value.country
    );
    this._usrsvc.newUserComplete(newUserData).subscribe({
      next: (res) => {
        this.submissionCompleted = res.isSuccess;
      },
      error: (err) => {
        console.error(err);
        if(typeof err.error == "object"){
          this.errorMessage = err.error.title;
        }
        if(typeof err.error == "string"){
          this.errorMessage = err.error;
        }
        this.buttonDisable = false;
      }
    });

  }

  submitValidation(token: string) {
    let password = encrypt(this.validateUserForm.value.password)
    let newValidateData = new NewUserValidate(this.uid, this.validateUserForm.value.username, password, token);

    this._usrsvc.newUserValidate(newValidateData).subscribe({
      next: (res) => {
        this.isValidated = res.isValidated;
        this.userId = res.userId;
      },
      error: (err) => {
        console.error(err);
        this.isValidated = false;
        this.userId = "";
        if(typeof err.error == "object"){
          this.errorMessage = err.error.title;
        }
        if(typeof err.error == "string"){
          this.errorMessage = err.error;
        }
        this.buttonDisable = false;
      }
    });
  }

  ngOnInit() {
    let uid = this.route.snapshot.params["uid"];
    if(uid == null || uid == ''){
      this.router.navigateByUrl('/login');
    }
    else {
      this.uid = uid;
    }
  }
}
