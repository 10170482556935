import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HamlogService } from '../hamlog.service';
import { LoggerService } from 'src/app/user/logger.service';
import { Hamlog } from '../hamlog.class';
import { DatePipe } from '@angular/common';
import { AmateurSearchService } from 'src/app/amateur/amateur-search.service';
import { Amateuren } from 'src/app/amateur/amateuren.class';
import { UserSettingService } from 'src/app/user/user-setting.service';
import { User } from 'src/app/user/user.class';
import { Modes } from 'src/app/settings/globalVars';


@Component({
  selector: 'app-hamlog-change',
  templateUrl: './hamlog-change.component.html',
  styleUrls: ['./hamlog-change.component.css']
})
export class HamlogChangeComponent {
  pageTitle = "Modify"

  user!: User;
  hamlog!: Hamlog;
  hamEn!: Amateuren;
  hamlogCurrentDate: string | null = null;
  pipe = new DatePipe('en-US');
  datepipe: DatePipe = new DatePipe('en-US');
  modeList: string[] = Modes
  reportToUser: string = "After Making Changes, Please Click SAVE";
  reportError: boolean = false;
  timeFormat: string = "h:mm:ss a";
  use24Hour: boolean = false;
  public timePickerScrollto: Date = new Date;



  constructor(
    private route: ActivatedRoute,
    private hamsvc: HamlogService,
    private router: Router,
    private sys: LoggerService,
    private amasvc: AmateurSearchService,
    private userSetSvc: UserSettingService
  ){}
  searchLocalFccData(): void {
    
    this.amasvc.getCallsign(this.hamlog.callsign).subscribe({
      next: (res) => {
        this.hamEn = res;
        this.hamlog.callsign = this.hamEn.callsign.toUpperCase();
        this.hamlog.address = this.hamEn.address1;
        this.hamlog.city = this.hamEn.city;
        this.hamlog.state = this.hamEn.state;
        this.hamlog.postalCode = this.hamEn.zip;
        this.hamlog.fullName = this.hamEn.fullName;
        this.hamlog.firstName = this.hamEn.first;
        this.hamlog.lastName = this.hamEn.last;
        this.hamlog.fccId = this.hamEn.fccid.toString();
        this.reportToUser = "Log Updated with new callsign information, Please click SAVE to continue"
        this.reportError = false;
      },
      error: (err) => {
        this.reportToUser = "NOT FOUND!";
        this.reportError = true;
        //try QRZ here?
        console.log(err);
      }
    })
  }
  save(): void {
    let editedHamlogToPost = new Hamlog;
    editedHamlogToPost = this.hamlog;
    let tOnDateObject = editedHamlogToPost.dateAndTime as Date;
    let tOffDateObject = editedHamlogToPost.timeOff as Date;
    tOffDateObject.setFullYear(tOnDateObject.getFullYear());
    tOffDateObject.setMonth(tOnDateObject.getMonth());
    tOffDateObject.setDate(tOnDateObject.getDate());
    editedHamlogToPost.dateAndTime = this.pipe.transform(tOnDateObject, 'yyyy-MM-ddTHH:mm:ss.sss');
    editedHamlogToPost.timeOff = this.pipe.transform(tOffDateObject, 'yyyy-MM-ddTHH:mm:ss.sss');
    editedHamlogToPost.callsign = editedHamlogToPost.callsign.toUpperCase();
    this.hamsvc.change(editedHamlogToPost).subscribe({
      next: (res) => {
        console.debug("Log Updated!");
        this.router.navigateByUrl(`/logging/newlog/${this.hamlog.userId}`)
      },
      error: (err) => {
        console.error(err);
      }
    })
  }
  initEJvariable(): void {
    this.timePickerScrollto = this.parseDateTime(Date.now());
  }

  parseDateTime(jsonDate) {
    let dateObject = new Date(jsonDate != null ? jsonDate: "");
    return dateObject //.toLocaleString('en-US');
  }
  openSessionTimeoutModal() {
    ($('#TimeOutModal') as any).modal('show');
  }

  ngOnInit(): void {
    this.user = this.sys.user;
    this.initEJvariable();
    let id = this.route.snapshot.params["id"];
    this.hamsvc.get(id).subscribe({
      next: (res) => {
        this.hamlog = res;
        this.hamlog.dateAndTime = this.parseDateTime(res.dateAndTime);
        this.hamlog.timeOff = this.parseDateTime(res.timeOff);
        if(this.sys.userId != this.hamlog.userId){
          this.router.navigateByUrl(`/logging/newlog/${this.sys.userId}`)
        }
        this.userSetSvc.get(this.sys.user.selectedSettingsProfileId).subscribe({
          next: (res) => {
            let modeSettings = res.logMode.split(",");
            this.use24Hour = modeSettings[2] == "USE24HOUR" ? true : false;
            this.timeFormat = this.use24Hour ? "HH:mm:ss" : "h:mm:ss a"
          },
          error: (err) => {
            console.error(err);
          }
        });
      },
      error: (err) => {
        console.error(err);
      }
    })

  }
}
