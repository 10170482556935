import { Component } from '@angular/core';
import { WeatherService } from '../weather.service';
import { UserSetting } from 'src/app/user/userSetting.class';
import { User } from 'src/app/user/user.class';
import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { LocationService } from '../location.service';
import { LoggerService } from 'src/app/user/logger.service';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrl: './weather.component.css'
})
export class WeatherComponent {

  selectedUserProfile?: UserSetting;
  use24Hour: boolean = false;
  lon: number = 0;
  lat: number = 0;

  city: string = "";
  state: string = "";
  tempCurrentHour: number = 0;
  elevation: number = 0;
  hourlyPeriods: Object[] = [];
  todayPeriods: Object[] = [];

  location: Object = Object;
  forecast: Object = Object;
  forecastHourly: Object = Object;

  public dateFormatOptions?: object;
  public timeFormatOptions?: object = { type: "date", format: "h:mm:ss a" };
  public options?: PageSettingsModel;


  constructor(
    private weatherService: WeatherService,
    private locService: LocationService,
    private loggersvc: LoggerService
  ) {}



  getCurrentLoc() {
    this.locService.getPosition().then(pos=>
      {
         console.log(`Positon: ${pos.lng} ${pos.lat}`);
         this.lat = pos.lat;
         this.lon = pos.lng;
         this.getLocationGrid();
         this.initFilterTemp();
      });
  }

  getForecast(url) {
    this.weatherService.getForecast(url).subscribe({
      next: (res) => {
        this.forecast = res;
        this.elevation = res.properties.elevation.value;
        this.todayPeriods = res.properties.periods;
        console.log(this.forecast)
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  getForecastHourly(url) {
    this.weatherService.getForecast(url).subscribe({
      next: (res) => {
        this.forecastHourly = res;
        this.tempCurrentHour = res.properties.periods[0].temperature;
        this.hourlyPeriods = res.properties.periods;
        console.log(this.forecastHourly)
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  getLocationGrid(){
    this.weatherService.getNoaaGrid(this.lat, this.lon).subscribe({
      next: (res) => {
        this.location = res;
        this.city = res.properties.relativeLocation.properties.city;
        this.state = res.properties.relativeLocation.properties.state;
        this.getForecast(res.properties.forecast);
        this.getForecastHourly(res.properties.forecastHourly)
        console.log(this.location);
        
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  initFilterTemp(){
    this.dateFormatOptions = {
      type: "date",
      format: "M/d/y"
    };
    this.timeFormatOptions = { type: "date", format: this.use24Hour ? "HH:mm:ss" : "h:mm:ss a" };
    this.options = { pageSize: 50 };

  }

  initJqueryListener() {
    $('#spaceWeather').on("click", () => {
      let imgSrc = $('#spaceWeather').attr('src');
      $('.imagepreview').attr('src', imgSrc == undefined ? "" : imgSrc);
      $('#imagemodal').modal('show');   
    });
  }


  refresh() {

    // this data is unavailable under certain instances causing an error.
    // let jsonString = sessionStorage.getItem('selectedProfile');
    // let jsonObj = JSON.parse(jsonString == null ? "": jsonString)
    // this.selectedUserProfile = jsonObj as UserSetting;
    // let modeSettings = ((this.selectedUserProfile) as UserSetting).logMode.split(",");
    // this.use24Hour = modeSettings[2] == "USE24HOUR" ? true : false;
    this.use24Hour = true;

    this.getCurrentLoc();

  }

  ngOnInit() {
    this.loggersvc.testUserData("/weather");
    this.initJqueryListener();
    this.refresh();
  }
}
