import { NgxSerial } from "../utilities/serial/ngx-serial";
import { hex2bin, hexToDecimal, unit8ToHex } from "../utilities/hexUtilities";
import { Iradio } from "../interfaces/interfaceRadio";
import { SerialOptions } from "../utilities/serial/serialOptions";
import { insertDecimal } from "../utilities/frequencyHelper";

export class FT1000MP implements Iradio {


    // ---------------- > Radio Lookup Tables

    hexBands = {
        '01': '',
        '02': '',
        '03': '160',
        '04': '160',
        '05': '',
        '06': '',
        '07': '',
        '08': '80',
        '09': '',
        '0A': '',
        '0B': '40',
        '0C': '',
        '0D': '',
        '0E': '',
        '0F': '',
        '10': '',
        '11': '20',
        '12': '',
        '13': '',
        '14': '',
        '15': '',
        '16': '',
        '17': '',
        '18': '',
        '19': '',
        '1A': '11',
        '1B': '10',
        '1C': '10',
    };
    binModes = {
        '000': 'LSB',
        '001': 'USB',
        '010': 'CW',
        '011': 'AM',
        '100': 'FM',
        '101': 'RTTY',
        '110': 'PKT',
    }
    modes = ['LSB', 'USB', 'CW', 'CW-R', 'AM', 'AM(sync)', 'FM', 'RTTY-L', 'RTTY-U', 'PKT-L', 'PKT-FM']
    selectModes = {
        'LSB': 0x00,
        'USB': 0x01,
        'CW': 0x02,
        'CW-R': 0x03,
        'AM': 0x04,
        'AM(sync)': 0x05,
        'FM': 0x06,
        'RTTY-L': 0x08,
        'RTTY-U': 0x09,
        'PKT-L': 0x0A,
        'PKT-FM': 0x0B,
    }

    // ------------ > Radio Commands
    commands = {
        'readRadioData': new Uint8Array([0x00, 0x00, 0x00, 0x02, 0x10]), // 16bit general information
        'readVFOAandVFOB': new Uint8Array([0x00, 0x00, 0x00, 0x03, 0x10]), // 32bit A and B
        'TXon': new Uint8Array([0x00, 0x00, 0x00, 0x01, 0x0F]),
        'TXoff': new Uint8Array([0x00, 0x00, 0x00, 0x00, 0x0F]),
    }

    opcodes = {
        'set_VFO_A': '0A', //converted to number later.
        'set_VFO_B': '8A',
        'set_Mode': 0x0C,
    }


    serialDefaultOptions: SerialOptions = new SerialOptions;

    serial!: NgxSerial;
    port: any;
    hex: string = "";
    currentFrequency: string = "";
    currentBand: string = "";
    currentMode: string = "";

    constructor(serialOptions: SerialOptions) {

        this.serialDefaultOptions = serialOptions

    }

    // ----------- > FUNCTIONS

    read() {
        this.hex = "";
        if (this.port) {
            this.serial.sendData(this.commands.readRadioData);
        }
    }

    changeFrequency(freqHz: string, selection?: string) {
        let utf8Bytes = this.getFrequencyBytesFromHz(freqHz, selection)

        if (utf8Bytes != null) {
            let unit8 = new Uint8Array(utf8Bytes);
            if (this.port) {
                this.serial.sendData(unit8);
            }
        }

    }
    changeMode(mode: string) {
        let unit8 = new Uint8Array([0x00, 0x00, 0x00, this.selectModes[mode], this.opcodes.set_Mode]);
        if (this.port) {
            this.serial.sendData(unit8);
        }
    }

    dataHandler(data: any) {
        let hex = unit8ToHex(data)
        this.hex += hex;
        if (this.hex.length == 32) {
            this.setData();
        }
    }

    private setData() {
        let freqHex = this.hex.slice(3, 9);
        let cf = parseInt(freqHex, 16).toString();
        this.currentFrequency = insertDecimal(cf);
        this.currentBand = this.calculateBand();
        this.currentMode = this.calculateMode();
    }

    connect() {
        if (!this.port) {
            this.serial.connect((port: any) => {
                this.port = port;
            });
        }
        return this.port;
    }

    close() {
        if (this.port)
            this.serial.close((port: any) => {
                this.port = port;

            });
    }

    initSerialDataHandler() {
        this.serial = new NgxSerial((data) => {
            this.dataHandler(data);
        }, this.serialDefaultOptions, false, false);
        return this.serial;
    }

    private calculateMode() {
        let modehex = this.hex.slice(14, 16);
        let modeBin = hex2bin(modehex);
        let last3bits = modeBin.substring(modeBin.length - 3);
        return this.binModes[last3bits];
    }

    private calculateBand() {
        let bandHex = this.hex.slice(0, 2);
        return this.hexBands[bandHex];
    }

    private getFrequencyBytesFromHz(hz: string, selection: string = "set_VFO_A") {
        let flipedBytes: string[] = [];
        let utf8Bytes: number[] = [];
        let lng = hz.length;
        let rev = hz.split("").reverse().join("");
        //add one leading 0 and remove first digit
        if (lng == 8) {
            rev = rev.slice(1);
            rev += "0";
        }
        else if (lng == 7) {
            rev = rev.slice(1);
            rev += "00"
        }
        else if (lng = 6) {
            rev = rev.slice(1);
            rev += "000"
        }
        else if (lng > 8) {
            return null;
        }
        else {
            return null;
        }
        let bytes = rev.match(/.{1,2}/g)
        if (bytes != null) {
            bytes.forEach((byte) => {
                byte = byte.split("").reverse().join("");
                flipedBytes.push(byte);
            });
        }
        switch (selection) {
            case "set_VFO_A":
                flipedBytes.push(this.opcodes.set_VFO_A); //0A command code
                break;
            case "set_VFO_B":
                flipedBytes.push(this.opcodes.set_VFO_B);
                break;
        }

        flipedBytes.forEach((byte) => {
            let decByte = 0;
            decByte = +("0x00" + byte);
            utf8Bytes.push(decByte);
        });
        return utf8Bytes;
    }
}
