import { DatePipe } from '@angular/common';


export const DaysOfTheWeek = ["Sunday", "Monday", "Tuseday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const Modes = ["LSB", "USB","FM", "RTTY", "PKT", "AM", "CW", "DIG"];
export const Bands = ["6", "10", "15", "20", "40", "80", "160"];
export const DefaultGuid = "00000000-0000-0000-0000-000000000000";

export function NumberDateToJson(numberDate) {
    let jsonFormatString = 'yyyy-MM-ddTHH:mm:ss.sss';
    let pipe = new DatePipe('en-US');
    return pipe.transform(numberDate, jsonFormatString)
}
