import { SerialOptions } from "../utilities/serial/serialOptions";

export var serialDefualts = {
    'yaesu-newer': {
        'FT-450D': new SerialOptions(38400, 8, 2, 'none', 256, 'none'),
        'FT-950': new SerialOptions(4800, 8, 2, 'none', 256, 'none'),
        'FT-1200': new SerialOptions(38400, 8, 2, 'none', 256, 'none'),
        'FT-2000': new SerialOptions(38400, 8, 2, 'none', 256, 'none'),
        'FT-3000': new SerialOptions(38400, 8, 2, 'none', 256, 'none'),
        'FT-5000': new SerialOptions(38400, 8, 2, 'none', 256, 'none'),
        'FT-9000': new SerialOptions(19200, 8, 2, 'none', 256, 'none'),
    },
    'ft1000mp': {
        'FT-1000MP': new SerialOptions(4800, 8, 2, 'none', 256, 'none')
    }
}
export var availableCatagories = [
    { value: 'yaesu-newer', text: 'Yaesu Newer', multiRadioSupport: true },
    { value: 'ft1000mp', text: 'Yaesu FT-1000MP', multiRadioSupport: false }
];

export var supportedRadios = {
    'yaesu-newer': {
        supportedRadios: [
            { name: "FT-450D", readCommand: "FA;", notes: "" },
            { name: "FT-950", readCommand: "FA;", notes: "" },
            { name: "FT-1200", readCommand: "FA;", notes: "some users report 4800 also works" },
            { name: "FT-2000", readCommand: "FA;", notes: "" },
            { name: "FT-3000", readCommand: "FA;", notes: "you can use VS; to return the active VFO" },
            { name: "FT-5000", readCommand: "FA;", notes: "" },
            { name: "FT-9000", readCommand: "FA;", notes: "" },
        ],
        value: 'yaesu-newer',
        text: 'Yaesu Newer',
        multiRadioSupport: true,
        textEncoding: true,
    },
    'ft1000mp': {
        supportedRadios: [
            { name: "FT-1000MP", readCommand: "00 00 00 02 10", notes: "May support other radios" },
        ],
        value: 'ft1000mp',
        text: 'Yaesu FT-1000MP',
        multiRadioSupport: false,
        textEncoding: false,
    }
}
export var possibleBaudRates = [4800, 9600, 19200, 38400, 57600, 115200, 230400, 460800, 921600];

export class SupportedRadio {
    name: string = "";
    notes: string = "";
    readCommand: string = "";
}