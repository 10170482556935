import { Component } from '@angular/core';
import { LoggerService } from 'src/app/user/logger.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent {

  constructor(
    private loggersvc: LoggerService
  ){}


  ngOnInit(){
    this.loggersvc.testUserData("/about");

  }
}
