import { Component } from '@angular/core';
import { AmateurSearchService } from '../amateur-search.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Amateuren } from '../amateuren.class';
import { HamlogService } from 'src/app/hamlog/hamlog.service';
import { LoggerService } from 'src/app/user/logger.service';
import { Hamlog } from 'src/app/hamlog/hamlog.class';
import { QrzService } from 'src/app/user/qrz.service';
import { qrzNonSub } from 'src/app/user/qrzNonSubscriber.class';
import { qrzSubscriber } from 'src/app/user/qrzSubscriber.class';

@Component({
  selector: 'app-amateur-search',
  templateUrl: './amateur-search.component.html',
  styleUrls: ['./amateur-search.component.css']
})
export class AmateurSearchComponent {
  hamEn!: Amateuren;
  hamlogs: Hamlog[] = [];
  pageTitle = "Amateur Search";
  qrzSubscriber!: qrzSubscriber;
  qrzNonSub!: qrzNonSub;
  qrzSub: boolean = true;
  qrzEnable: boolean = false;
  constructor(
    private fccsvc: AmateurSearchService,
    private router: ActivatedRoute,
    private hamsvc: HamlogService,
    private sys: LoggerService,
    private qrz: QrzService,
    private routing: Router
  ){}
  refresh(): void {
    this.hamEn = new Amateuren;
    this.qrzNonSub = new qrzNonSub;
    this.qrzSubscriber = new qrzSubscriber;
  
    let search = this.router.snapshot.params["callsign"];
    let userId = this.sys.userId;
    this.hamsvc.getByCallUser(search, userId).subscribe({
      next: (res) => {
        this.hamlogs = res;
      },
      error: (err) => {
        console.error(err);
      }
    });
    this.fccsvc.getCallsign(search).subscribe({
      next: (res) => {
        this.hamEn = res;
        console.debug(res);
        
      },
      error: (err) => {
        console.error(err);
      }
    });
    if(sessionStorage.getItem('qrze') == 'true'){
      this.qrz.searchQrz(search).subscribe({
        next: (res)  => {
          if(res.QRZDatabase.Session != null){
            if(res.QRZDatabase.Session.Error != null){
              this.qrz.qrzLogin(sessionStorage.getItem('qrzu'), sessionStorage.getItem('qrzp')).subscribe({
                next:(res)=> {
                  if(res.QRZDatabase.Session.Error != null){
                    return;
                  }
                  else {
                    if(res.QRZDatabase.Session != null){
                      sessionStorage.setItem('qrzSessionKey', res.QRZDatabase.Session.Key);
                      this.qrz.searchQrz(search).subscribe({
                        next:(res) => {
                          if(res.QRZDatabase.Session.SubExp == "non-subscriber"){
                            this.qrzSub = false;
                            this.qrzNonSub = res.QRZDatabase.Callsign;
                          }
                          else{
                            this.qrzSubscriber = res.QRZDatabase.Callsign;
                          }
                        }
                      });
                    }
                  }
                }
              });
            }
            else{
              if(res.QRZDatabase.Session.SubExp == "non-subscriber"){
                this.qrzSub = false;
                this.qrzNonSub = res.QRZDatabase.Callsign;
              }
              else {
                this.qrzSubscriber = res.QRZDatabase.Callsign;
              }
            }
          }
        }
      });
    }
  }

  
  ngOnInit(): void {
      this.router.params.subscribe(({ callsign }) => this.refresh());
      if(sessionStorage.getItem('qrze') == 'true'){
        this.qrzEnable = true;
      }
    }
  }

