import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { UserService } from './user.service';
import * as xml2js from 'xml2js';



@Injectable({
  providedIn: 'root'
})
export class QrzService {

  constructor(
    private http: HttpClient,
    private usersvc: UserService,
  ) { }

   
  qrzLogin(usr: string | null, pwd: string | null): Observable<any> {
    let password = this.usersvc.decryptData(pwd);
    let userName = this.usersvc.decryptData(usr);
    return this.http.get(`https://xmldata.qrz.com/xml/current/?username=${userName};password=${password}`, { responseType: 'text' })
    .pipe(switchMap(async xml => await this.parseXml(xml))) as Observable<any>; 
  }

  searchQrz(callsign: string): Observable<any> {
    return this.http.get(`https://xmldata.qrz.com/xml/current/?s=${sessionStorage.getItem('qrzSessionKey')};callsign=${callsign}`, { responseType: 'text' })
    .pipe(switchMap(async xml => await this.parseXml(xml))) as Observable<any>; 
  }
  

    async parseXml(xml){
      var output;
      const parser = new xml2js.Parser({explicitArray : false});
      await parser.parseString(xml, function (err, result) {
        output = result;
      });
      return output;
    }
  }

