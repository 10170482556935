export class PasswordResetRequest {
    constructor(userName: string, 
                emailAddress: string, 
                token: string){
        this.userName = userName;
        this.emailAddress = emailAddress;
        this.token = token;
    }
    userName: string = "";
    emailAddress: string = "";
    token: string = "";
}
export class PasswordResetSubmitData {
    constructor(uid: string, 
                userId: string, 
                token: string, 
                newPassword: string) {
        this.uid = uid;
        this.userId = userId;
        this.token = token;
        this.newPassword = newPassword;
    }
    uid: string = "";
    userId: string = "";
    token: string = "";
    newPassword: string = "";
}
export class NewUserRequestForm {
    constructor(email: string, 
                callsign: string, 
                username: string, 
                password: string, 
                token: string) {
        this.email = email;
        this.callsign = callsign;
        this.username = username;
        this.password = password;
        this.token = token;
    }
    email: string = "";
    callsign: string = "";
    username: string = "";
    password: string = "";
    token: string = "";
}
export class NewUserValidate {
    constructor(uid: string, 
                username: string, 
                password: string, 
                token: string){
        this.uid = uid;
        this.username = username;
        this.password = password;
        this.token = token;
    }
    uid: string = "";
    username: string = "";
    password: string = "";
    token: string = "";
}
export class NewUserForm {
    constructor(uid: string,
                userId: string,
                token: string,
                firstName: string,
                lastName: string,
                address: string,
                address2: string,
                city: string,
                state: string,
                zip: string,
                country: string,
    ) {
        this.uid = uid;
        this.userId = userId;
        this.token = token;
        this.firstName = firstName;
        this.lastName = lastName;
        this.address = address;
        this.address2 = address2;
        this.city = city;
        this.state = state;
        this.zip = zip;
        this.country = country;
    }
    uid: string = "";
    userId: string = "";
    token: string = "";
    firstName: string = "";
    lastName: string = "";
    address: string = "";
    address2: string = "";
    city: string = "";
    state: string = "";
    zip: string = "";
    country: string = "";
}