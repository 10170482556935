import { Component, ViewChild } from '@angular/core';
import { NewsitemService } from '../newsitem.service';
import { NewsItem } from '../newsitem.class';
import { ToastComponent } from '@syncfusion/ej2-angular-notifications';

@Component({
  selector: 'app-editnewsitem',
  templateUrl: './editnewsitem.component.html',
  styleUrl: './editnewsitem.component.css'
})
export class EditnewsitemComponent {

  newsItems: NewsItem[] = [];
  @ViewChild('toastMessageBox') public toastMessageBox?: ToastComponent;
  public toastMessagePosition = { X: 'Right', Y: 'Top' };
  toastMessageContent: string = "";
  toastMessageTitle: string = "";

  constructor(
    private newsSvc: NewsitemService

  ){}
  toast(title, content, colorClass = "") {
    $('#toast').removeClass('toastRed');
    $('#toast').removeClass('toastGreen');
    (this.toastMessageBox as ToastComponent).cssClass = colorClass;
    this.toastMessageTitle = title;
    this.toastMessageContent = content;
    (this.toastMessageBox as ToastComponent).show();
  }

  addNewNewsItem() {
    let newEmptyNewsItem = new NewsItem
    this.newsItems.push(newEmptyNewsItem);
  }

  save() {
    this.newsSvc.updateList(this.newsItems).subscribe({
      next: res => {
        console.log(res);
        this.refresh();
        this.toast("Success!", "News Items have been successfully updated.", "toastGreen");

      },
      error: err => {
        console.error(err);
      }
    });
  }
  delete(id){
    if(id == "00000000-0000-0000-0000-000000000000") {
        this.newsItems.pop();
    }
    else {
      this.newsSvc.remove(id).subscribe({
        next: res => {
          this.refresh();
        },
        error: err => {
          console.error(err);
        }
      });
    }
  }

  refresh() {
    this.newsSvc.list().subscribe({
      next: res => {
        this.newsItems = res;
      },
      error: err => {
        console.error(err);
      }
    });
  }
  ngOnInit() {
    this.refresh();
  }
}
