<app-menu></app-menu>
<div id="component">
    <header>
        <span id="pageTitle">{{pageTitle}}</span>
        <span> | </span>
        <a routerLink="/user/create" class="btn btn-primary btn-sm">New User</a>
    </header>
    <div class="table-responsive">
        <table class="table table-sm table-hover user-table">
            <thead>
                <tr>
                    <th>Control</th>
                    <th>Status</th>
                    <th>Record Count</th>
                    <th>Admin</th>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Callsign</th>
                    <th>Email</th>
                    <th>Email Valid?</th>
                    <th>Address</th>
                    <th>Details</th>
                    <th>Change</th>
                    <th>Id</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let u of users" [ngClass]="setRowColor(u.status, u.isDisabled)">
                    <td *ngIf="u.isDisabled"><a (click)="approveUser(u.id)" class="btn btn-success btn-sm">Approve</a></td>
                    <td *ngIf="!u.isDisabled"><a (click)="disableUser(u.id)" class="btn btn-danger btn-sm">Disable</a></td>
                    <td>{{u.status}}</td>
                    <td>{{u.recordCount}}</td>
                    <td>{{(u.isAdmin) ? "YES" : "NO"}}</td>
                    <td>{{u.firstName}} {{u.lastName}}</td>
                    <td>{{u.userName}}</td>
                    <td>{{u.callsign}}</td>
                    <td>{{u.email}}</td>
                    <td>{{(u.emailValidated) ? "YES" : "NO"}}</td>
                    <td>{{u.address}}</td>
                    
                    <td><a routerLink="/user/detail/{{u.id}}" class="btn btn-primary btn-sm">Detail</a></td>
                    <td><a routerLink="/user/change/{{u.id}}" class="btn btn-warning btn-sm">Change</a></td>
                    <td>{{u.id}}</td>
                </tr>
            </tbody>
        </table>

    </div>
</div>
