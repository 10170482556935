import { Iradio } from "../interfaces/interfaceRadio";
import { NgxSerial } from "../utilities/serial/ngx-serial";
import { SerialOptions } from "../utilities/serial/serialOptions";

export class YaesuNewer implements Iradio {

    // --------------> Commands
    commandFrquencyHz?: string;
    commands = {
        'set_VFO_A': 'FA' + this.commandFrquencyHz + ";", // FA14250000;
        'set_VFO_B': 'FB' + this.commandFrquencyHz + ";",
        'set_Mode': 'MD0', // + mode option MD01;
        'read_Mode': 'MD0;',
        'read_VFO_A': 'FA;',
        'read_VFO_B': 'FB;',
        'TX/RX_VFO_A': 'FT0;', //toggle
        'TX/RX_VFO_B': 'FT1;', //toggle
    }
    modes = ['LSB', 'USB', 'CW', 'FM', 'AM', 'FSK', 'CW-R', 'PKT-L', 'FSK-R', 'PKT-FM', 'FM-N', 'PKT-U', 'AM-N'];
    selectModes = {
        'LSB': '1;',
        'USB': '2;',
        'CW': '3;',
        'FM': '4;',
        'AM': '5;',
        'FSK': '6;',
        'CW-R': '7;',
        'PKT-L': '8;',
        'FSK-R': '9;',
        'PKT-FM(': 'A;',
        'FM-N': 'B;',
        'PKT-U': 'C;',
        'AM-N': 'D;',
        'MD01;': 'LSB',
        'MD02;': 'USB',
        'MD03;': 'CW',
        'MD04;': 'FM',
        'MD05;': 'AM',
        'MD06;': 'FSK',
        'MD07;': 'CW-R',
        'MD08;': 'PKT-L',
        'MD09;': 'FSK-R',
        'MD0A;': 'PKT-FM',
        'MD0B;': 'FM-N',
        'MD0C;': 'PKT-U',
        'MD0D;': 'AM-N',
    }


    serialDefaultOptions: SerialOptions = new SerialOptions

    currentMode: string = "";
    currentBand: string = "";
    currentFrequency: string = "";
    serial!: NgxSerial;
    port: any;

    constructor(serialOptions: SerialOptions) {

        this.serialDefaultOptions = serialOptions
    }
    changeMode(newMode: string) {
        throw new Error("Method not implemented.");
    }
    changeFrequency(newFreq: string, vfoToChange: string): void {
        this.commandFrquencyHz = newFreq;
        switch (vfoToChange) {
            case "set_VFO_A":
                this.serial.sendData(this.commands.set_VFO_A);
                break;
            case "set_VFO_B":
                this.serial.sendData(this.commands.set_VFO_B);
                break;
            default:
                break;
        }
    }

    read() {
        if (this.port) {
            this.serial.sendData(this.commands.read_VFO_A);
            this.serial.sendData(this.commands.read_Mode);
        }
    }

    dataHandler(data: any): void {
        if (data.includes("FA")) {
            this.getFrequencyHandler(data);
        }
        else if (data.includes("MD")) {
            this.getModeHandler(data);
        }
        else {
            return;
        }
    }

    connect() {
        if (!this.port) {
            this.serial.connect((port: any) => {
                this.port = port;
            });
        }
        return this.port;
    }

    close(): void {
        if (this.port)
            this.serial.close((port: any) => {
                this.port = port;

            });
    }


    private getFrequencyHandler(data) {
        console.log(data);
    }
    private getModeHandler(data) {
        console.log(data);
    }

    initSerialDataHandler() {
        this.serial = new NgxSerial((data) => {
            this.dataHandler(data);
        }, this.serialDefaultOptions, true, true, ";");
        return this.serial;
    }



}