import { Component, HostBinding, Input, ViewChild, viewChild } from '@angular/core';
import { SpotService } from '../spot.service';
import { Spot } from '../dxspot.class';
import { Subscription, map, timer } from 'rxjs';
import { UserService } from 'src/app/user/user.service';
import { LoggerService } from 'src/app/user/logger.service';
import { User } from 'src/app/user/user.class';
import { Column, FilterSettingsModel, GridComponent, IFilterUI, parentsUntil } from '@syncfusion/ej2-angular-grids';
import { DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import { DatePicker } from '@syncfusion/ej2-angular-calendars';
import { hasFlag } from 'country-flag-icons';

@Component({
  selector: 'app-dxspot',
  templateUrl: './dxspot.component.html',
  styleUrls: ['./dxspot.component.css']
})


export class DxspotComponent {

  spots?: Spot[];
  timerSubscription!: Subscription;
  paused: boolean = false;
  pageTitle = "DX Spots"
  spotsToShow: string = "50"; 
  user!: User;
  refreshRate: number = 10000;
  allBands: string[] = ["6", "10", "15", "20", "40", "80", "160"];
  public dateFormatOptions?: object;
  public timeFormatOptions?: object = { type: "date", format: "HH:mm:ss" };
  public templateOptionsDropDown?: IFilterUI;
  public templateOptionsDatePicker?: IFilterUI;
  public dateElement?: HTMLInputElement;
  public dropdown?: HTMLElement;
  public filterSettings?: FilterSettingsModel;

  @ViewChild('grid') grid?: GridComponent;


  @Input() tableHeight = 300;
  

  constructor(
    private spot:SpotService,
    private usrsvc: UserService,
    private sys: LoggerService
  ){}

  hasFlagInData(cont): boolean{
    let b = hasFlag(cont)
    return b
  }
  pause(): void {
    this.timerSubscription.unsubscribe(); 
    this.paused = true;
    
  }
  unPause():void {
    this.ngOnInit();
    this.paused = false;
  }

  

  refresh(): void {
    this.spot.listSpots(Number(this.spotsToShow)).subscribe({
      next: (res) => {
        this.spots = res;
      },
      error: (err) => {
        console.error(err);
      }
    })
  }
  initTable() {
    //(this.grid as GridComponent).isAutoGenerateColumns = false;
    this.dateFormatOptions = {
      type: "date",
      format: "M/d/y"
    };
    this.filterSettings = {
      mode: 'Immediate',
    };
  }
  initFilterTemplates() {
    this.templateOptionsDropDown = {
      create: () => {
        this.dropdown = document.createElement('select');
        this.dropdown.id = 'band';

        var option = document.createElement('option');
        option.value = 'All';
        option.innerText = 'All';
        this.dropdown.appendChild(option);

        this.allBands.forEach((item) => {
          let option = document.createElement('option');
          option.value = item.toString();
          option.innerText = item.toString();
          (this.dropdown as HTMLElement).appendChild(option);
        });
        return this.dropdown;
      },
      write: () => {
        const dropdownlist = new DropDownList({
          change: this.dropdownFunction.bind(this),
        });
        dropdownlist.appendTo(this.dropdown);
      },
    };
    this.templateOptionsDatePicker = {
      create: () => {
        this.dateElement = document.createElement('input');
        return this.dateElement;
      },
      write: (args: { column: { field: string | number | Date } }) => {
        const datePickerObj = new DatePicker({
          value: new Date(args.column.field),
          change: this.handleFilterChange.bind(this),
        });
        datePickerObj.appendTo(this.dateElement);
      },
    };
  }
  dropdownFunction(args: { value: string; item: { parentElement: { id: string } } }
  ) {
    if (args.value !== 'All') {
      (this.grid as GridComponent).filterByColumn(args.item.parentElement.id.replace('_options', ''), 'equal', Number(args.value));
    } else {
      (this.grid as GridComponent).removeFilteredColsByField(args.item.parentElement.id.replace('_options', ''));
    }
  }
  public handleFilterChange(args: { element: Element; value: string }) {
    let targetElement = parentsUntil(args.element, 'e-filtertext');
    let columnName: string = targetElement.id.replace('_filterBarcell', '');
    if (args.value) {
      (this.grid as GridComponent).filterByColumn(columnName, 'equal', args.value);
    } else {
      (this.grid as GridComponent).removeFilteredColsByField(columnName);
    }
  }
  tableOnLoad() {
    ((this.grid as GridComponent).columns[0] as Column).filterTemplate = '<span></span>';

  }

  ngOnInit(): void {
    this.initTable();
    this.initFilterTemplates();
    let userId = this.sys.userId;
    this.usrsvc.get(userId).subscribe({
      next: (res) => {
        this.user = res;
        //TODO
        this.refreshRate = 1000;
      },
      error: (err) => {
        console.error(err);
      }
    })
          // timer(0, 10000) call the function immediately and every 10 seconds 
          this.timerSubscription = timer(0, this.refreshRate).pipe( 
            map(() => { 
              this.refresh(); // load data contains the http request 
            }) 
          ).subscribe(); 
          
          
          
  }
  ngOnDestroy(): void { 
    this.timerSubscription.unsubscribe(); 
  } 

}
