import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
  } from '@angular/forms';
  
  /*
  contains at least: 
  1 Lowercase letter
  1 Uppercase letter
  1 Number
  1 special character
  lenth > 8
  */
  export const passwordRules: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+~])[A-Za-z\d@$!%*?&+~]{8,}$/;
  //export const callsignRules: RegExp = /^(\d{1}){4,}$/;
  export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    return control.value.password1 === control.value.password2 ? null: { PasswordNoMatch: true };
  };
