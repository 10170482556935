import { Component } from '@angular/core';
import { encrypt } from 'node_modules/dsi-encrypt-password/index.js';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { confirmPasswordValidator, passwordRules } from '../custom-validators.validator';
import { PasswordResetSubmitData } from '../requests.class';
@Component({
  selector: 'app-newpassword',
  templateUrl: './newpassword.component.html',
  styleUrl: './newpassword.component.css'
})
export class NewpasswordComponent {
  newPasswordForm!: FormGroup;
  isValidated: boolean = false;
  submissionCompleted: boolean = false;
  validationCheckCompleted: boolean = false;
  oldPassword: string = "";
  userId: string = "";
  errorMessage: string = "";
  uid: string = "";
  password1: FormControl = new FormControl(null, [
    (c: AbstractControl) => Validators.required(c),
                            Validators.pattern(passwordRules)]);
  password2: FormControl = new FormControl(null, [
    (c: AbstractControl) => Validators.required(c),
                            Validators.pattern(passwordRules)]);
                            
                            

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _usrsvc: UserService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private formBuilder: FormBuilder
  ) {
    this.newPasswordForm = this.formBuilder.group({
      password1: this.password1,
      password2: this.password2
    },{ validators: confirmPasswordValidator.bind(this) });
  }

  //TODO Encryption logic


  validateCaptcha() {
    this.recaptchaV3Service.execute('importantAction')
    .subscribe({
      next: (res) => {
        this.submit(res);
      },
      error: (err) => {
        console.error(err);
        this.errorMessage = "Recaptcha could not be validated.";
      }
    });
  }

  submit(token: string) {
    let newPassword = encrypt(this.newPasswordForm.value.password2);
    if(newPassword == this.oldPassword){
      this.errorMessage = "Password Must Be Different Than Previously Used Password.";
      return;
    }
    let submitData = new PasswordResetSubmitData(this.uid, this.userId, token, newPassword);
    this._usrsvc.passwordResetReset(submitData).subscribe({
      next: (res) => {
        this.submissionCompleted = res.isSuccess;
      },
      error: (err) => {
        console.error(err);
        this.isValidated = false;
        this.userId = "";
        this.errorMessage = err.message;
        if(typeof err.error == "object"){
          this.errorMessage = err.error.title;
        }
        if(typeof err.error == "string"){
          this.errorMessage = err.error;
        }
      }
    });
  }
  ngOnInit() {
    let uid = this.route.snapshot.params["uid"];
    if(uid == null || uid == ''){
      this.router.navigateByUrl('/login');
    }
    else {
      this.uid = uid;
      this._usrsvc.passwordResetValidate(uid).subscribe({
        next: (res) => {
          this.isValidated = res.isValidated;
          this.oldPassword = res.oldPassword;
          this.userId = res.userId;
          this.errorMessage = "";
          this.validationCheckCompleted = true;
        },
        error: (err) => {
          this.isValidated = false;
          this.userId = "";
          this.errorMessage = err.message;
          if(typeof err.error == "object"){
            this.errorMessage = err.error.title;
          }
          if(typeof err.error == "string"){
            this.errorMessage = err.error;
          }
          this.validationCheckCompleted = true;
        }
      });
    }
  }
}
