import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginPageComponent } from './user/login-page/login-page.component';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './menu/menu/menu.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserChangeComponent } from './user/user-change/user-change.component';
import { UserCreateComponent } from './user/user-create/user-create.component';
import { UserDetailComponent } from './user/user-detail/user-detail.component';
import { AmateurSearchComponent } from './amateur/amateur-search/amateur-search.component';
import { HamlogListcreateComponent } from './hamlog/hamlog-listcreate/hamlog-listcreate.component';
import { FreqencyPipe } from './pipes/freqency.pipe';
import { SorthamlogPipe } from './pipes/sorthamlog.pipe';
import { HamlogDetailComponent } from './hamlog/hamlog-detail/hamlog-detail.component';
import { CalcBandPipe } from './pipes/calc-band.pipe';
import { HamlogChangeComponent } from './hamlog/hamlog-change/hamlog-change.component';
import { SettingsListComponent } from './settings/settings-list/settings-list.component';
import { AboutComponent } from './core/about/about.component';
import { FooterComponent } from './core/footer/footer.component';
import { ChangesComponent } from './core/changes/changes.component';
import { DocumentationComponent } from './core/documentation/documentation.component';
import { RequestAccountComponent } from './user/request-account/request-account.component';
import { RadioComponent } from './radio/radio/radio.component';
import { DxspotComponent } from './dxspots/dxspot/dxspot.component';
import { DxconfigComponent } from './dxspots/dxconfig/dxconfig.component';
import { DxdetailComponent } from './dxspots/dxdetail/dxdetail.component';
import { GridModule, FilterService, PageService, ResizeService, SortService, ReorderService, FreezeService } from '@syncfusion/ej2-angular-grids';
import { ComboBoxModule, MultiSelectModule, CheckBoxSelectionService, DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { CheckBoxModule, ButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { enableRipple } from '@syncfusion/ej2-base';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { TextBoxModule, ColorPickerModule, NumericTextBoxModule  } from '@syncfusion/ej2-angular-inputs';
import { TimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { LoggingInterceptor } from './interceptors/logging.interceptor';
import { TimeOutModalComponent } from './core_components/time-out-modal/time-out-modal.component';
import { EditnewsitemComponent } from './settings/newsitems/editnewsitem/editnewsitem.component';
import { NetpresetsComponent } from './settings/netpresets/editnetpresets/netpresets.component';
import { WeatherComponent } from './weather/weather/weather.component';
import { SubMenuComponent } from './menu/sub-menu/sub-menu.component';
import { SerialInterfaceComponent } from './rig_interface/serial-interface/serial-interface.component';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { NewpasswordComponent } from './user/newpassword/newpassword.component';
import { NewuserComponent } from './user/newuser/newuser.component';

import * as bootstrap from "bootstrap"
import * as $ from 'jquery';
import { sort } from '@syncfusion/ej2-angular-charts';
enableRipple(true);



@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    HomeComponent,
    MenuComponent,
    UserListComponent,
    UserChangeComponent,
    UserCreateComponent,
    UserDetailComponent,
    AmateurSearchComponent,
    HamlogListcreateComponent,
    FreqencyPipe,
    SorthamlogPipe,
    HamlogDetailComponent,
    CalcBandPipe,
    HamlogChangeComponent,
    SettingsListComponent,
    AboutComponent,
    RadioComponent,
    DxspotComponent,
    DxconfigComponent,
    DxdetailComponent,
    FooterComponent,
    ChangesComponent,
    DocumentationComponent,
    RequestAccountComponent,
    TimeOutModalComponent,
    EditnewsitemComponent,
    WeatherComponent,
    SubMenuComponent,
    SerialInterfaceComponent,
    NetpresetsComponent,
    NotFoundComponent,
    NewpasswordComponent,
    NewuserComponent,
    

  ],
  imports: [
    BrowserModule,
    GridModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MultiSelectModule,
    DropDownListAllModule,
    CheckBoxModule,
    ButtonModule,
    DatePickerModule,
    TextBoxModule,
    TimePickerModule,
    SwitchModule,
    ComboBoxModule,
    ToastModule,
    ColorPickerModule,
    NumericTextBoxModule,
    RecaptchaV3Module,

    



  ],
  providers: [FilterService, PageService, CheckBoxSelectionService, ResizeService, 
              SortService, ReorderService, FreezeService, { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.RecaptchaSiteKey },
              { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
