import * as utf8 from 'utf8'
// This is the same for all of the below, and
// you probably won't need it except for debugging
// in most cases.
const LUT_HEX_4b = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'];

export function unit8ToHex(buffer) {
    let out = '';
    for (let idx = 0; idx < buffer.length; idx++) {
        let n = buffer[idx];
        out += LUT_HEX_4b[(n >>> 4) & 0xF];
        out += LUT_HEX_4b[n & 0xF];
    }
    return out;
}

export function hex2bin(hex){
    return (parseInt(hex, 16).toString(2)).padStart(8, '0');
}

export function hexToDecimal(hex) {
    return parseInt(hex, 16);
}


export function bytesToHex(bytes) {
    return Array.from(
        bytes,
        byte => (byte as any).toString(16)//.padStart(2, "0")
    ).join("");
}

// You almost certainly want UTF-8, which is
// now natively supported:
export function stringToUTF8Bytes(string) {
    //let encoded = utf8.encode(string);
    let encoded = new TextEncoder().encode(string)
    return encoded;

}

// But you might want UTF-16 for some reason.
// .charCodeAt(index) will return the underlying
// UTF-16 code-units (not code-points!), so you
// just need to format them in whichever endian order you want.
export function stringToUTF16Bytes(string, littleEndian) {
    const bytes = new Uint8Array(string.length * 2);
    // Using DataView is the only way to get a specific
    // endianness.
    const view = new DataView(bytes.buffer);
    for (let i = 0; i != string.length; i++) {
        view.setUint16(i, string.charCodeAt(i), littleEndian);
    }
    return bytes;
}

// And you might want UTF-32 in even weirder cases.
// Fortunately, iterating a string gives the code
// points, which are identical to the UTF-32 encoding,
// though you still have the endianess issue.
export function stringToUTF32Bytes(string, littleEndian) {
    const codepoints = Array.from(string, c => (c as any).codePointAt(0));
    const bytes = new Uint8Array(codepoints.length * 4);
    // Using DataView is the only way to get a specific
    // endianness.
    const view = new DataView(bytes.buffer);
    for (let i = 0; i != codepoints.length; i++) {
        view.setUint32(i, codepoints[i], littleEndian);
    }
    return bytes;
}