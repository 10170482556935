import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Hamlog } from 'src/app/hamlog/hamlog.class';
import { NewsitemService } from 'src/app/settings/newsitems/newsitem.service';
import { User } from 'src/app/user/user.class';
import { UserSetting } from 'src/app/user/userSetting.class';


@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrl: './sub-menu.component.css'
})
export class SubMenuComponent {

    @Input() pageTitle?: string;
    @Input() user?: User;
    @Input() userProfile?: UserSetting;
    @Input() searching?: boolean;
    @Input() isNetRtMode?: boolean;
    @Input() showLookupPreviousLogs?: boolean;
    @Input() lookupPreviousLogs?: boolean;
    @Input() checkincount?: number;
    @Input() searchResMessage?: string;
    @Input() hamlogs?: Hamlog[];

  
    @Output() showLookupPreviousLogsChange = new EventEmitter();
    @Output() onSessionTimerClick: EventEmitter<any> = new EventEmitter();
    @Output() onEditSelectedRowsClick: EventEmitter<any> = new EventEmitter();
    @Output() onClearSelectedRowsClick: EventEmitter<any> = new EventEmitter();
    @Output() onRecallNetlogsClick: EventEmitter<any> = new EventEmitter();
    @Output() onSaveClick: EventEmitter<any> = new EventEmitter();
    @Output() onRigControlControlClick: EventEmitter<any> = new EventEmitter();
    @Output() onRigControlSettingsClick: EventEmitter<any> = new EventEmitter();
    @Output() onRigControlConnectClick: EventEmitter<any> = new EventEmitter();

    constructor(
      private newsSvc: NewsitemService
    ) {}


    openSessionTimeoutModal(): void {
        this.onSessionTimerClick.emit();
    }
    editSelectedRows(): void {
      this.onEditSelectedRowsClick.emit();
    }
    clearSelectedRows() {
      this.onClearSelectedRowsClick.emit();
    }
    recallNetsListModalFire() {
      this.onRecallNetlogsClick.emit();
    }
    saveBtnClick() {
      this.onSaveClick.emit();
    }
    setShowLookupPreviousLogs() {
      this.showLookupPreviousLogsChange.emit(this.showLookupPreviousLogs);
    }
    rigControlBtnClick(){
      this.onRigControlControlClick.emit();
    }
    rigControlSettingsClick() {
      this.onRigControlSettingsClick.emit();
    }
    rigControlConnectClick() {
      this.onRigControlConnectClick.emit();
    }


    setNewsItems() {
      this.newsSvc.list().subscribe({
        next: res => {
          let out = "";
          let today = new Date().getTime();
          res.forEach((item) => {
            let start = new Date(item.dateStart as Date).getTime();
            let end = new Date(item.dateEnd as Date).getTime();
            if(today >= start && today <= end){
              out += `<span style="color:${item.fontColor};">${item.news} - </span>`
            }
          });
          $('#marqueeScroller').prepend(out);
        },
        error: err => {
          console.error(err);
        }
      });
    }
    ngOnInit() {
      this.setNewsItems();
    }

    


}
