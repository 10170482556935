<app-menu></app-menu>

<app-sub-menu (onSessionTimerClick)="openSessionTimeoutModal()" (onSaveClick)="save()" [user]="user"
  pageTitle="Settings" [searching]="true"></app-sub-menu>
<app-time-out-modal></app-time-out-modal>

<app-serial-interface #rigcontrol *ngIf="currentSelectedProfile && currentSelectedProfile.rigControlEnabled"
  [profileId]="currentSelectedProfile.id"></app-serial-interface>

<!-- Modal UPLOAD FILE -->
<div class="modal fade" id="SetUploadModal" tabindex="-1" aria-labelledby="SetUploadModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="SetUploadModalLabel">Import ADIF File</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <input type="file" style="display: none;" accept=".adi" #importCSV (change)="fileSelection($event)">
        <button class="btn btn-sm btn-primary" style="margin: 10px;" [disabled]="currentlyUploading"
          (click)="importCSV.click()">Browse</button>
        <input [value]="fileName">
        <button class="btn btn-sm btn-success" style="margin: 10px;" [disabled]="!currentFile || currentlyUploading"
          (click)="uploadFileAdi()">Upload</button>
        <button *ngIf="currentlyUploading && !uploadSuccess" class="btn btn-sm btn-danger"
          style="margin: 10px; margin-left: 5px;" (click)="cancelUpload()">Cancel</button>
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
            [style.width]="progress + '%'">
            {{progress}}%
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div style="display: block; width: 100%;">
          <div style="float:left; margin: auto;">
            <div *ngIf="uploadSuccess" style="display: block;">
              <span style="font-weight: bold;">Upload Details:</span><br>
              <span>QSO Count: {{newLogRowCount}}</span><br>
              <span>Duplicates: {{newLogDuplicates}}</span>
            </div>
          </div>
          <div style="margin: auto"></div>
          <div style="float: right; margin: auto;">
            <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
            <span style="padding-left: 20%;"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal New Profile -->
<div class="modal fade" id="NewProfileModal" tabindex="-1" aria-labelledby="NewProfileModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="NewProfileModalLabel">Create A New Profile</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <table>
          <tr>
            <td style="font-weight: bold;">Profile Name:</td>
            <td><ejs-textbox [(value)]="newProfile.profileName"></ejs-textbox></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Calls to show in Table:</td>
            <td><ejs-dropdownlist [dataSource]="numberOfCallsToShow"
                [(value)]="newProfile.priviousCallsToShow"></ejs-dropdownlist></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Log Mode:</td>
            <td><ejs-dropdownlist [dataSource]="logModeBaseOptions" [(value)]="newLogModeOption"></ejs-dropdownlist>
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Use 24 hour:</td>
            <td><ejs-switch [(checked)]="newUse24Hour"></ejs-switch></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Lookup previous logs:</td>
            <td><ejs-checkbox [(ngModel)]="newAutomaticLookupPreviousLogs"></ejs-checkbox></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Show NET Column:</td>
            <td><ejs-checkbox [(ngModel)]="newProfile.showNetColumn"></ejs-checkbox></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Show Address Column:</td>
            <td><ejs-checkbox [(ngModel)]="newProfile.showAddressColumn"></ejs-checkbox></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Show DX Spots</td>
            <td><ejs-checkbox [(ngModel)]="newProfile.showDxSpot"></ejs-checkbox></td>
          </tr>
        </table>
      </div>
      <div class="modal-footer">
        <button class="btn btn-sm btn-success" (click)="createNewUserProfile()" data-bs-dismiss="modal">Create</button>
        <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
        <span style="padding-left: 20%;"></span>

      </div>
    </div>
  </div>
</div>

<!-- Modal Edit Profile -->
<div class="modal fade" id="EditProfileModal" tabindex="-1" aria-labelledby="EditProfileModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="EditProfileModalLabel">Edit Profile:</h5>
        <ejs-dropdownlist style="display: inline-block; margin-left: 10px; margin-right: 10px;"
          [dataSource]="availableProfiles" [fields]="fields" [(value)]="selectProfileId"
          (change)="changeSelectedProfile($event)"></ejs-dropdownlist>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="resetModalOptions()"></button>
      </div>
      <div class="modal-body">
        <span *ngIf="!editableProfile" style="color: red;">Note: The Default Profile Cannot Be Removed.</span>
        <table *ngIf="currentSelectedProfile">
          <tr>
            <td style="font-weight: bold;">Profile Name:</td>
            <td><ejs-textbox [(value)]="currentSelectedProfile.profileName" [enabled]="editableProfile"></ejs-textbox>
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Calls to show in Table:</td>
            <td><ejs-dropdownlist [dataSource]="numberOfCallsToShow"
                [(value)]="currentSelectedProfile.priviousCallsToShow"></ejs-dropdownlist></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Log Mode:</td>
            <td><ejs-dropdownlist [dataSource]="logModeBaseOptions" [(value)]="logModeOptions[0]"></ejs-dropdownlist>
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Use 24 hour:</td>
            <td><ejs-switch [(checked)]="use24Hour"></ejs-switch></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Lookup previous logs:</td>
            <td><ejs-checkbox [(ngModel)]="automaticLookupPreviousLogs"></ejs-checkbox></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Show Net Column:</td>
            <td><ejs-checkbox [(ngModel)]="currentSelectedProfile.showNetColumn"></ejs-checkbox></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Show Address Column:</td>
            <td><ejs-checkbox [(ngModel)]="currentSelectedProfile.showAddressColumn"></ejs-checkbox></td>
          </tr>
          <tr>
            <td style="font-weight: bold;">Show DX Spotting:</td>
            <td><ejs-checkbox [(ngModel)]="currentSelectedProfile.showDxSpot"></ejs-checkbox></td>
          </tr>
        </table>
      </div>
      <div class="modal-footer">
        <div style="display: block; width: 100%;">
          <div style="float: left; margin: auto;">
            <button style="margin: 10px;" type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"
              (click)="resetModalOptions()">Close</button>
          </div>
          <div style="margin: auto;"></div>
          <div style="float: right; margin: auto;">
            <button style="margin: 10px;" class="btn btn-sm btn-success" (click)="editSaveSelectedProfile()"
              data-bs-dismiss="modal">Save</button>
            <button style="margin: 10px; margin-left: 0px;" class="btn btn-sm btn-danger"
              (click)="deleteProfileFromModal()" [disabled]="!editableProfile">{{modalDeleteOrCancel}}</button>
            <button *ngIf="areYouSureModalDelete" (click)="deleteFromModalVerified()" class="btn btn-danger btn-sm"
              data-bs-dismiss="modal">ARE YOU SURE?</button>

          </div>
          <span style="padding-left: 20%;"></span>

        </div>

      </div>
    </div>
  </div>
</div>
<table>
  <tr>
    <td>
      <div style="margin-top:10px; margin-bottom: 10px;">
        <span style="font-weight: bold; margin-right: 10px; display: inline-block;">Message Center: </span>
        <input *ngIf="error" style="color:red;" readonly [value]="message" [size]="myInput.value.length" #myInput>
        <input *ngIf="!error" style="color:green;" readonly [value]="message" [size]="myInput.value.length" #myInput>
      </div>
    </td>
  </tr>
  <tr>
    <td style="border: 2px solid black;">
      <span style="margin-left: 10px; font-weight: bold;">Available Profiles:</span>
      <ejs-dropdownlist style="display: inline-block; margin-left: 10px; margin-right: 10px;"
        [dataSource]="availableProfiles" [fields]="fields" [(value)]="selectProfileId"
        (change)="changeSelectedProfile($event)"></ejs-dropdownlist><br>

      <button style="margin: 10px; margin-right: 0px;" class="btn btn-sm btn-primary" data-bs-toggle="modal"
        data-bs-target="#NewProfileModal">New Profile</button>
      <button style="margin: 10px;" class="btn btn-sm btn-primary" data-bs-toggle="modal"
        data-bs-target="#EditProfileModal" (click)="setOriginalPname()">Edit</button>
    </td>
  </tr>
  <tr>
    <td style="padding: 5px;"></td>
  </tr>
  <tr>
    <td style="border: 2px solid black;">
      <span style="margin-left: 10px; margin-right: 10px; font-weight: bold;">Enable QRZ lookup:</span><ejs-switch
        [(checked)]="qrzEnabled" (change)="changeUserQRZsettings()"></ejs-switch><br>
      <div *ngIf="qrzEnabled">
        <form>
          <table>
            <tr>
              <td>
                <span style="font-weight: bold;">QRZ Username:</span>
              </td>
              <td>
                <ejs-textbox autocomplete="username" [(value)]="user.qrzUserName"></ejs-textbox>
              </td>
            </tr>
            <tr>
              <td>
                <span style="font-weight: bold;">QRZ Password:</span>
              </td>
              <td>
                <ejs-textbox type="password" autocomplete="current-password" [(value)]="user.qrzPassword"></ejs-textbox>
              </td>
              <td>
                <button style="margin-left: 10px;" class="btn btn-sm btn-primary"
                  (click)="changeUserQRZsettings()">Save</button>
              </td>
            </tr>
          </table>
        </form>
      </div>
    </td>
  </tr>
  <tr>
    <td style="padding: 5px;"></td>
  </tr>
  <tr>
    <td style="border: 2px solid black;" *ngIf="currentSelectedProfile">
      <span style="margin-left: 10px; margin-right: 10px; font-weight: bold;">Enable Rig Control:</span><ejs-switch
        [(checked)]="currentSelectedProfile.rigControlEnabled"
        (change)="editSaveSelectedProfile(true)"></ejs-switch><br>
      <button *ngIf="currentSelectedProfile.rigControlEnabled" style="margin: 10px;"
        class="btn btn-sm btn-primary" (click)="rigControl?.openSerialSettingsModal()">Settings</button>
    </td>
  </tr>
  <tr>
    <td style="padding: 5px;"></td>
  </tr>
  <tr>
    <td style="border: 2px solid black;">
      <span style="font-weight: bold; margin-left: 10px;">Upload Files</span><br>
      <button style="margin: 10px; margin-right: 5px;" class="btn btn-sm btn-primary" data-bs-toggle="modal"
        data-bs-target="#SetUploadModal">Import ADIF File</button>
    </td>
  </tr>
</table>


<!-- <div>
    <label>Show NET column on results? </label>
<input type="checkbox" [(ngModel)]="user.showNetColumn" *ngIf="user"><br>
<label>Show DX Spotting? </label>
<input type="checkbox" [(ngModel)]="user.showDxSpot" *ngIf="user">
</div> -->

<!-- <span>this is some text</span>

<button (click)="save()">Save</button> -->